// modules
import React from 'react';

function LogoutButton(props) {
  return (
    <>
      <div className="w-[18%] h-[2rem] mx-auto">
        <div className="bg-primaryColor hover:bg-primaryColorDark rounded-full text-center font-semibold">
          <button className="w-[100%] h-[100%]" onClick={props.logOutClick}>
            Log out 👋
          </button>
        </div>
      </div>
    </>
  );
}

export default LogoutButton;
