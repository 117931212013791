// modules
import React from 'react';

// css
import '../../assets/css/mainpage.css';

//APIs

//image

// components

function PageNotFoundMo() {
  return (
    <div>
      <div className="w-screen font-workSans">
        <div className="my-[25%]">
          <div className="text-center mt-[5%]">
            <div className="text-3xl font-extrabold">404</div>
            <div className="text-3xl font-extrabold">NOT FOUND !</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFoundMo;
