// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';

// icons
import { PiWarningDuotone } from 'react-icons/pi';

function DisclaimerPc() {
  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <>
      {/* Disclaimer */}
      <div
        tabIndex={0}
        className="bg-lightBlackColor rounded-lg mt-[3rem] px-[1.5rem] py-[1rem]"
      >
        <div className="text-xl flex font-bold font-medium text-lightGrayColor">
          <PiWarningDuotone className="h-[1.8rem]" />
          <div className="ml-[0.5rem]">Investment Disclaimer</div>
        </div>
        <div className="mt-[1rem]">
          <p>
            ※ 투자자는 금융투자상품(서비스 및 계약 포함)에 대하여
            신한투자증권으로부터 충분한 설명을 받을 권리가 있으며, 투자전
            상품설명서 및 약관을 반드시 읽어보시기 바랍니다.
          </p>
          <p className="font-black">
            ※ 이 금융투자상품(서비스 및 계약 포함)은 예금자보호법에 따라
            보호되지 않습니다.
          </p>
          <p className="font-black">
            ※ 금융투자상품(서비스 및 계약 포함)은 투자원금의 손실이 발생할 수
            있으며, 그 손실은 투자자에게 귀속됩니다.
          </p>
          <p>
            ※ 관련 서비스 계약 시 월 이용료 등 서비스별 수수료가 부과될 수
            있습니다.
          </p>
          <p className="mt-[2rem] text-sm text-right">
            신한투자증권 준법감시인 심사필 제24-0993호 (2024년 05월 27일 ~
            2025년 05월 27일)
          </p>
        </div>
      </div>
    </>
  );
}

export default DisclaimerPc;
