// modules
import React from 'react';

// image
const favicon = require('../../assets/images/favicon.png');

function FooterPc() {
  return (
    <footer className="footer footer-center py-7 w-[100%] bg-beigeColor mt-[10rem] text-black">
      <aside className="">
        <img alt="favicon" src={favicon} className="w-[3rem]"></img>

        <p className="font-bold">
          SHINHAN SECURITIES CO.LTD. <br />
          Leading brokerage and investment banking houses.
        </p>
        <p>Copyright © 2024 - All right reserved</p>
      </aside>
    </footer>
  );
}

export default FooterPc;
