// modules
import axios from 'axios';

// URL
import config from '../config/config';

//Notice
export async function getNotice(page) {
  const requestURL = `${config.apiURL}/announcement?page=${page}`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}

export async function getNoticeCount() {
  const requestURL = `${config.apiURL}/announcement?page=0`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}

export async function getNoticeByTitle(title) {
  const requestURL = `${config.apiURL}/announcement/search?title=${title}`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}

//Meeting
export async function getMeeting(page) {
  const requestURL = `${config.apiURL}/report?page=${page}`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}

export async function getMeetingCount() {
  const requestURL = `${config.apiURL}/report?page=0`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}

export async function getMeetingByTitle(title) {
  const requestURL = `${config.apiURL}/report/search?title=${title}`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}

export async function getLoginState() {
  const requestURL = `${config.apiURL}/login_state`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}

export async function getLogout() {
  const requestURL = `${config.apiURL}/auth/logout`;

  const data = await axios
    .get(requestURL)
    .then((result) => result.data)
    .catch((err) => {
      throw err;
    });

  return data;
}
