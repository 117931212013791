// modules
import React from 'react';

// components

// css
import '../../assets/css/mainpage.css';

//APIs

//image

function PageNotFoundPc() {
  return (
    <div>
      <div className="w-screen font-workSans">
        <div className="">
          <div className="flex my-[20%] justify-center">
            <div className="my-auto text-7xl font-extrabold">
              404 NOT FOUND !
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFoundPc;
