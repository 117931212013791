// modules
import React from 'react';
import { Link } from 'react-router-dom';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// components
import { ReactComponent as NonMonetaryTrustServiceSVG } from '../../assets/images/svgImage/NonMonetaryTrust_service.svg';
import { ReactComponent as InvestmentContractServiceSVG1 } from '../../assets/images/svgImage/InvestmentContract_service_1.svg';
import { ReactComponent as InvestmentContractServiceSVG2 } from '../../assets/images/svgImage/InvestmentContract_service_2.svg';
import { ReactComponent as InvestmentContractServiceSVG3 } from '../../assets/images/svgImage/InvestmentContract_service_3.svg';
import { ReactComponent as InvestmentContractServiceSVG4 } from '../../assets/images/svgImage/InvestmentContract_service_4.svg';

// components
import DisclaimerMo from '../../components/mobile/DisclaimerMo';
import ContactMo from '../../components/mobile/ContactMo';

// css
import '../../assets/css/mainpage.css';

// image

function NonMonetaryTrustMo() {
  const googleQA =
    'https://docs.google.com/forms/d/1yWvQVOCJYYd_RwmtrEE5UmrUKchCwubn0t57Uvd3UYo/viewform?edit_requested=true#responses';

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://shinhandigitalasset.com/trust',
              name: '신탁수익증권',
              description:
                '비금전 신탁수익증권 구조 설계 컨설팅 서비스, 증권신고서 작성 자문 서비스, 혁신금융서비스 신청 및 지정, 투자자 모집 전략 자문 서비스, 기초자산 신탁 및 고객 계좌 관리 서비스 등 금융투자업의 전문성을 바탕으로 최적의 서비스 제공',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '서울 영등포구 여의도동 의사당대로 96',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'shs.block@shinhan.com',
                  contactType: 'email',
                },
              ],
              keywords: [
                '토큰증권',
                '가상자산',
                '비트코인',
                '블록체인',
                '금융위원회',
                '얼라이언스',
                '신한투자증권',
                '유진투자증권',
                '미래에셋',
                'KB증권',
                'NH증권',
                '한국투자증권',
                '키움증권',
                '삼성증권',
                'SK증권',
                'STO얼라이언스',
                'NFI',
                '넥스트 파이낸스 이니셔티브',
                'Next Finance Initiative',
                'ST 오너스',
                'ST 프렌즈',
                'STO 비전그룹',
                'STO',
                'Security Token',
                'Crypto',
                'Bitcoin',
                'Blockchain',
                'STO Alliance',
                '투자계약증권',
                '비금전신탁수익증권',
                'Securities',
                'Shinhan',
                'Token',
                'Bitcoin etf',
                'Bitcoin spot etf',
                '블록체인 인프라',
                '분산원장',
                'Project Pulse',
                '프로젝트 펄스',
                'RWA',
                '조각투자',
                'Investment',
                'Investment Contract',
                'Non-monetary Trust Beneficiary Certificates',
                'Fragment Investment',
                'Digital Asset',
                'Digital Wallet',
                'Web3',
                'Smart Contract',
                'Blockchain Global',
                '신종증권',
                '전자증권',
                'Digital',
                'ETF',
                'NFT',
                '혁신금융서비스',
                '조각투자사업자',
                '증권신고서',
                '예치금자금관리',
                '투자자보호기금',
                '에이판다',
                '발행시장',
                '유통시장',
                'Secondary Market',
                '서울옥션블루',
                '갤럭시아머니트리',
                '열매컴퍼니',
                '미술품 조각투자',
                '금융 솔루션',
                'Financial Advisory Service',
                'Business Consulting',
                'STO Platform',
                'STO 플랫폼',
                '증권업계',
                '매출채권',
                '유동화',
                '투자자문',
                '사업구조 설계',
                '증권구조',
                '드웰파이',
                'DwellFi',
                'Franklin Templeton',
                '금융 컨설팅',
                '유가증권',
                'Financial Institution',
                'Korean Financial Institution',
                'Korea Digital Asset',
                'Ethereum',
              ],
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '08:00:00',
                },
              ],
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div style={{ backgroundColor: '#000000' }} className="font-workSans">
          <div className="w-screen mt-[8rem]">
            {/* STO Description*/}
            <div className="w-[90%] mx-auto text-center">
              <div className="text-3xl w-[100%] mb-[2rem] font-bold">
                Non-monetary
                <span className="text-primaryColor"> Trust</span> Beneficiary
                Certificates
              </div>
              <div>
                <div className="flex-col">
                  <div className="mt-[2rem]">
                    <div className="">
                      <div className="text-2xl font-bold">
                        비금전 신탁수익증권이란?
                      </div>
                      <div>
                        <div className="text-lightGrayColor">
                          <ul>
                            <li>
                              부동산·저작권 등 비금전 자산을 유동화해 신탁사가
                              수익증권으로 발행한 것
                            </li>
                            <li>
                              투자자에게 금전적 수익 대신 다른 형태의 비금전적
                              혜택을 제공
                            </li>
                          </ul>
                        </div>
                        <div className="text-2xl font-bold mt-[0.5rem]">
                          투자계약증권과 비금전 신탁수익증권
                        </div>

                        <div className="text-lightGrayColor">
                          <ul>
                            <li>
                              도산 절연의 관점에서 투자계약증권은 투자자가 직접
                              취득한 소유권의 형태로만 발행되지만 비금전
                              신탁수익증권은 독립된 신탁회사가 기초자산을
                              안전하게 보관 및 관리
                            </li>
                            <li>
                              투자계약증권은 장외시장 유통이 불가능하나 비금전
                              신탁수익증권은 장외시장 유통 가능
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <NonMonetaryTrustServiceSVG className="w-[70%] mt-[1rem] mx-auto" />

                    {/* Description */}
                    <div className="mt-[2rem] text-sm">
                      <div className="text-xl font-bold">Our purpose</div>
                      <div className="text-lightGrayColor">
                        당 부서는 금융투자업의 전문성과 비금전 신탁수익증권의
                        높은 이해를 갖춘 인력을 바탕으로 고객에게 최적의
                        서비스를 제공하여 고객의 비즈니스 번영에 힘쓰는 것에
                        목표를 두고 있습니다
                      </div>
                      <div className="text-xl font-bold mt-[0.5rem]">
                        What services do we provide?
                      </div>
                      <div className="text-lightGrayColor">
                        비금전 신탁수익증권 구조 설계 컨설팅 서비스, 기초자산
                        신탁 서비스, 혁신금융서비스 신청 및 지정 컨설팅 서비스
                        및 증권신고서 작성 자문 서비스 등 비금전 신탁수익증권
                        관련 전반의 서비스를 제공합니다
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* STO Services Description */}
              <div className="text-3xl font-bold mt-[10rem]">
                Achieving your{' '}
                <span className="text-primaryColor">objectives</span> is our{' '}
                <span className="text-primaryColor">top priority</span>
              </div>
              <div className="mt-[2rem] text-2xl font-bold">
                금융투자업의 전문성을 바탕으로 비금전 자산 유동화 및 신탁 상품에
                대한 권리를 다룹니다
              </div>
              {/* row 1 */}
              <div className="flex-col mt-[2rem]">
                <div className="">
                  <div>
                    <InvestmentContractServiceSVG1 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-base font-bold mt-[1rem]">
                      비금전 신탁수익증권 구조 설계 컨설팅 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>비금전 신탁수익증권 사업구조 자문 서비스 제공</li>
                        <li>
                          기초자산의 증권형태 결정 및 상품구조화(비금전
                          신탁수익증권)
                        </li>
                        <li>
                          금융투자상품 구조화 자문 및 개발, 투자자보호 방안 수립
                        </li>
                        <li>
                          높은 블록체인 기술 이해도와 다양한 경험을 보유한
                          인력으로 구성된 전담 부서 보유
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <div>
                    <InvestmentContractServiceSVG2 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-xl font-bold mt-[1rem]">
                      증권신고서 작성 자문 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>
                          전문기관과의 협업을 통해 작성 및 자문 서비스 제공 (1부
                          작성, 2부 검토)
                        </li>
                        <li>
                          비금전 신탁수익증권은 기존에 발행되지 않던 증권형태를
                          다루기에 증권신고서 작성이 무척 까다로우며, 전문
                          기관의 지원이 필수적
                        </li>
                        <li>
                          증권신고서 작성 경험이 풍부한 IB 인력, 조각투자 및
                          비금전 신탁수익증권에 높은 이해도를 갖춘 인력으로
                          구성된 부서 보유
                        </li>
                        <li>
                          법무법인, 회계법인 등 외부 네트워크 확보를 통해
                          추가적인 협업 가능
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* row 2 */}
              <div className="flex-col">
                <div className="mt-[1rem]">
                  <div>
                    <InvestmentContractServiceSVG3 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-xl font-bold mt-[1rem]">
                      혁신금융서비스 신청 및 지정, 투자자 모집 전략 자문 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>
                          혁신금융서비스 제도: 기존 금융서비스와의 차별성이
                          인정되는 금융업 또는 이와 관련된 업무를 수행하는
                          과정에서 제공되는 서비스에 대해 지정 범위 내에서 시범
                          영업 및 임시 규제특례 적용을 인정하는 제도
                        </li>
                        <li>
                          혁신금융서비스 지정 신청서 작성 요령 및 관련 서비스
                          제공
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <div>
                    <InvestmentContractServiceSVG4 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-xl font-bold mt-[1rem]">
                      기초자산 신탁 및 고객 계좌 관리 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>신탁을 통해 기초자산을 안전하게 보관 및 관리</li>
                        <li>
                          특정금전신탁 제공, 조각투자사업자 발행 상품을 하나의
                          신탁으로 한번에 관리
                        </li>
                        <li>도산절연 장치로서의 역할 수행</li>
                        <li>조각투자전용계좌 개설 및 관리 서비스 제공</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact */}
              <ContactMo />

              <DisclaimerMo />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NonMonetaryTrustMo;
