// modules
import React from 'react';
import { useEffect, useState } from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// components
import Pagination from '../../components/pc/Pagination';
import NoticeCardMo from '../../components/mobile/NoticeCardMo';
import Loading from '../../components/pc/LoadingPc';

// css
import '../../assets/css/noticeMo.css';

//APIs
import {
  getNotice,
  getNoticeCount,
  getNoticeByTitle,
} from '../../apis/request';

//image

function NoticeMo() {
  //Notice Information
  const [notices, setNotices] = useState([]);

  // 전체 Notice의 갯수를 저장합니다.
  const [noticeLength, setNoticeLength] = useState('');

  // 하나의 page에 들어갈 데이터를 저장합니다
  const [page, setPage] = useState(1); // 현재 페이지를 저장합나다.
  const handlePageChange = (page) => {
    // pagination 버튼이 클릭되면 해당 함수가 작동되어 현재 page를 저장합니다.
    setPage(page);
    // 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
    window.scrollTo(0, 0);
  };
  const limit = 10; // posts가 보일 최대한의 갯수

  // search 값을 저장합니다
  const [search, setSearch] = useState('');

  // search값을 실시간으로 저장합니다
  const saveSearch = (event) => {
    setSearch(event.target.value);
  };

  // loading 상태관리
  const [loding, setLoding] = useState(true);

  // search값으로 notice를 조회합니다
  const searchClick = (event) => {
    const getData = () => {
      getNoticeByTitle(search)
        .then((response) => {
          return response.data.resources;
        })
        .then((data) => {
          setNotices(data);
          setNoticeLength(data.length);
        });
    };
    getData();
  };

  //search 값의 enter를 감지하고 enter를 통해 검색을 실행합니다
  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchClick(event);
    }
  };

  useEffect(() => {
    const noticeData = getNotice(page);
    const lengthData = getNoticeCount();
    const getAPIData = () => {
      noticeData
        .then((response) => {
          setNotices(response.data.resources);

          return response.data.resources;
        })
        .then((result) => {
          if (result) {
            setLoding(false);
          }
        });

      lengthData.then((response) => {
        setNoticeLength(response.data.count);

        return response.data.count;
      });
    };

    getAPIData();
  }, [page]);

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Dataset',
              url: 'https://shinhandigitalasset.com/notice',
              name: '공지사항',
              description:
                '얼라이언스는 STO 사업에 대한 전문적인 지식과 경험을 공유합니다. 해당 페이지를 통해 정기적인 네트워크 활동과 사업을 확장에 대한 정보를 확인할 수 있습니다.',
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div className="w-screen font-workSans mt-[6rem]">
          <div className="w-[80%] mx-auto">
            <div className="text-4xl font-black">
              {language === false ? '공지사항' : 'Notice'}
            </div>
            <div className="text-base mt-[1.5rem]">
              <span>{language === false ? '얼라이언스로부터' : 'Get a'}</span>
              <span className="text-primaryColor">
                &nbsp;{language === false ? '공지사항' : 'notice'}
              </span>
              <span className="">
                {language === false ? '을 받으세요' : ' from our alliance.'}
              </span>
            </div>
            <div className="text-darkGrayColor mt-[0.7rem] w-[70%] text-xs">
              {language === false
                ? '얼라이언스는 STO 사업에 대한 전문적인 지식과 경험을 공유합니다. 해당 페이지를 통해 정기적인 네트워크 활동과 사업을 확장에 대한 정보를 확인할 수 있습니다.'
                : 'We have professional knowledge and experience in the STO business.The partnership between the alliance member companies is strong. We are preparing to expand our STO business through partnerships.'}
            </div>
          </div>

          {/* search */}
          <div className="w-screen font-workSans mt-[1.5rem] mb-[1rem] font-workSans">
            <div className="w-[80%] mx-auto">
              <div className="w-[40%] h-[2rem] bg-white rounded-l-full relative">
                <input
                  className="w-[80%] h-[2rem] ml-[5%] rounded-full"
                  placeholder="Text"
                  onChange={saveSearch}
                  onKeyPress={handleOnKeyPress}
                />
                <div className="search h-[2rem] bg-primaryColor rounded-full absolute hover:bg-primaryColorDark transition delay-100">
                  <button
                    className="px-[0.7rem] py-[0.5rem] font-black text-[10px]"
                    onClick={searchClick}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* notice cards */}
          {loding ? (
            <div className="my-auto">
              <div className="mx-auto my-[15rem] flex justify-center">
                <Loading />
              </div>
            </div>
          ) : (
            <div className="NoticeContainer w-screen font-workSans">
              <div className="NoticeCardsMo w-[80%] mx-auto">
                {notices.map((notice) => {
                  return (
                    <div key={notice.object_key}>
                      <NoticeCardMo
                        title={notice.title}
                        created_date={notice.created_date}
                        created_by={notice.created_by}
                        content={notice.content}
                        object_key={notice.object_key}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className="mt-[5rem]">
            <Pagination
              page={page}
              limit={limit}
              totalPosts={Number(noticeLength)}
              handlePageChange={handlePageChange} //현재 페이지의 위치를 설정하는 handlePageChange props로 넘긴다
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default NoticeMo;
