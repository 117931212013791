// modules
import React from 'react';

function AdminMeetingCard(props) {
  // 버튼 클릭시 클락한 공지사항의 데이터를 저장 (상태 끌어올리기)
  const meetingTitleClick = () => {
    props.setModalMeetingData({
      title: props.title,
      created_date: props.created_date,
      created_by: props.created_by,
    });

    // 버튼 클릭시 Title을 수정하는 Modal이 열릴 수 있도록 상태제어
    props.setEditTitleModalIsOpen(true);
  };

  return (
    <>
      <div className="meetingCard rounded-xl mt-[0.5rem]">
        <div className="py-[1rem] px-[2rem] flex">
          <div className="text-xl">
            <div className="font-extrabold">{props.title}</div>
            <div className="flex mt-[0.5rem]">
              <div className="flex items-end">
                <div className="text-base">Date</div>
                <div className="ml-[0.5rem] text-sm text-center text-darkGrayColor">
                  {props.created_date}
                </div>
              </div>
              <div className="flex items-end ml-[2.5rem]">
                <div className="text-base">Created by</div>
                <div className="ml-[0.5rem] text-sm text-darkGrayColor">
                  {props.created_by}
                </div>
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="h-[100%] flex items-center">
              <button className="w-[100%] bg-green rounded-full hover:bg-darkGreen transition delay-100 drop-shadow-md font-bold">
                <div className="px-[1rem] text-lg" onClick={meetingTitleClick}>
                  제목 수정
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminMeetingCard;
