// modules

import React from "react";

function MeetingCard(props) {
  return (
    <>
      <div className="meetingCard rounded-xl mt-[0.5rem]">
        <div className="py-[1rem] px-[2rem]">
          <div className="inline-block text-sm w-[100%] font-extrabold">
            {props.title}
          </div>
          <div className="flex mt-[2%]">
            <div className="flex">
              <div className="text-xs">Date</div>
              <div className="text-xs ml-[0.3rem] text-darkGrayColor">
                {props.created_date}
              </div>
            </div>
            <div className="flex ml-[7%]">
              <div className="text-xs">Created by</div>
              <div className="text-xs ml-[0.3rem] text-darkGrayColor">
                {props.created_by}
              </div>
            </div>
          </div>
          <div className="">
            <button className="w-[100%] mt-[5%] bg-primaryColor rounded-full hover:bg-primaryColorDark transition delay-100 drop-shadow-md bg-primaryColor rounded-2xl font-bold">
              <div className="px-[1rem] text-sm">
                <a href={props.download_link}>Download</a>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeetingCard;
