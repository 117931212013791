// module
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import TranslationButtonPc from './components/pc/TranslationButtonPc';
import TranslationButtonMo from './components/mobile/TranslationButtonMo';
import languageAtom from './utils/GlobalState';
import { useAtom } from 'jotai';

// css
import './App.css';

// mediaQuery
import { Mobile, Pc } from './mediaQuery';

// components (PC)
import HeaderPc from './components/pc/HeaderPc';
import FooterPc from './components/pc/FooterPc';

// components (Mo)
import HeaderMo from './components/mobile/HeaderMo';
import FooterMo from './components/mobile/FooterMo';

// pages (PC)
import MainPc from './pages/pc/MainPc';
import PressPc from './pages/pc/PressPc';
import ServicesPc from './pages/pc/ServicesPc';
import StoPc from './pages/pc/StoPc';
import InvestmentContractPc from './pages/pc/InvestmentContractPc';
import NonMonetaryTrustPc from './pages/pc/NonMonetaryTrustPc';
import SandboxPc from './pages/pc/SandboxPc';
import NoticePC from './pages/pc/NoticePc';
import NoticeDetailsPC from './pages/pc/NoticeDetailsPc';
import AboutUsPc from './pages/pc/AboutUsPc';
import AdminPostNoticePc from './pages/pc/AdminPostNoticePc';
import AdminUpFilePc from './pages/pc/AdminUpFilePc';
import AdminEditNoticePc from './pages/pc/AdminEditNoticePc';
import AdminEditMeetingPc from './pages/pc/AdminEditMeetingPc';
import AdminRouterPc from './pages/pc/AdminRouterPc';
import AdminPassportAuthPc from './pages/pc/AdminPassportAuthPc';
import PageNotFoundPC from './pages/pc/PageNotFoundPc';

// pages (mobile)
import MainMo from './pages/mobile/MainMo';
import PressMo from './pages/mobile/PressMo';
import ServicesMo from './pages/mobile/ServicesMo';
import StoMo from './pages/mobile/StoMo';
import InvestmentContractMo from './pages/mobile/InvestmentContractMo';
import NonMonetaryTrustMo from './pages/mobile/NonMonetaryTrustMo ';
import SandboxMo from './pages/mobile/SandboxMo';
import NoticeMo from './pages/mobile/NoticeMo';
import NoticeDetailsMo from './pages/mobile/NoticeDetailsMo';
import AboutUsMo from './pages/mobile/AboutUsMo';
import PageNotFoundMo from './pages/mobile/PageNotFoundMo';

function App() {
  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <>
      <Pc>
        <HeaderPc />
        <Routes>
          <Route path="/" element={<MainPc />} />
          <Route path="/press" element={<PressPc />} />
          <Route path="/services" element={<ServicesPc />} />
          <Route path="/sto" element={<StoPc />} />
          <Route
            path="/investmentContract"
            element={<InvestmentContractPc />}
          />
          <Route path="/trust" element={<NonMonetaryTrustPc />} />
          <Route path="/sandbox" element={<SandboxPc />} />
          <Route path="/notice" element={<NoticePC />} />
          <Route path="/noticeDetails" element={<NoticeDetailsPC />} />
          <Route path="/aboutUs" element={<AboutUsPc />} />
          <Route path="/postNotice" element={<AdminPostNoticePc />} />
          <Route path="/uploadFile" element={<AdminUpFilePc />} />
          <Route path="/editNotice" element={<AdminEditNoticePc />} />
          <Route path="/editMeeting" element={<AdminEditMeetingPc />} />
          <Route path="/adminRouter" element={<AdminRouterPc />} />
          <Route path="/adminPassportAuth" element={<AdminPassportAuthPc />} />
          <Route path="*" element={<PageNotFoundPC />} />
        </Routes>
        {/* <div className="fixed bottom-11 right-11 z-50">
          <div className="flex justify-center">
            <TranslationButtonPc />
          </div>
          <div className="flex justify-center mt-[0.5rem]">
            {' '}
            {language === false ? (
              <div className="font-bold">🇰🇷 KO</div>
            ) : (
              <div className="font-bold">🇺🇸 EN</div>
            )}
          </div>
        </div> */}
        <FooterPc />
      </Pc>

      <Mobile>
        <HeaderMo />
        <Routes>
          <Route path="/" element={<MainMo />} />
          <Route path="/press" element={<PressMo />} />
          <Route path="/services" element={<ServicesMo />} />
          <Route path="/sto" element={<StoMo />} />
          <Route
            path="/investmentContract"
            element={<InvestmentContractMo />}
          />
          <Route path="/trust" element={<NonMonetaryTrustMo />} />
          <Route path="/sandbox" element={<SandboxMo />} />
          <Route path="/notice" element={<NoticeMo />} />
          <Route path="/noticeDetails" element={<NoticeDetailsMo />} />
          <Route path="/aboutUs" element={<AboutUsMo />} />
          <Route path="*" element={<PageNotFoundMo />} />
        </Routes>
        {/* <div className="fixed bottom-11 right-11 z-50">
          <div className="flex justify-center">
            <TranslationButtonMo />
          </div>
          <div className="flex justify-center mt-[0.5rem]">
            {' '}
            {language === false ? (
              <div className="font-bold text-xs">🇰🇷 KO</div>
            ) : (
              <div className="font-bold text-xs">🇺🇸 EN</div>
            )}
          </div>
        </div> */}
        <FooterMo />
      </Mobile>
    </>
  );
}

export default App;
