// modules
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import Modal from 'react-modal';

// css
import '../../assets/css/adminEditNoticeModal.css';

//APIs
import { patchNoticeContentData } from '../../apis/patch';

// components
import ToastMessage from './ToastMessagePc';

function AdminEditNoticeContentModalPc(props) {
  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // modal style 설정
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  };

  // User가 서버로 patch요청을 보낼 데이터를 저장합니다
  const [new_content, setNewContent] = useState('');

  // content를 실시간으로 저장합니다
  const saveNewContent = (event) => {
    setNewContent(event.target.value);
  };

  // Toast Message 성공 알람, 실패 알람
  const success = () =>
    toast.success(' 내용 수정 완료 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const error = (message) =>
    toast.error(' 내용 수정 실패 ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  const modifyContentClick = (event) => {
    const patchData = () => {
      patchNoticeContentData(props.modalNoticeData.title, new_content)
        .then((response) => {
          console.log(response.data.success);

          return response;
        })
        .then((response) => {
          if (response.data.success === true) {
            setNewContent('');
            props.setModalIsOpen(false);
            success();
          } else {
            error(response.data.message);
          }
        });
    };

    patchData();
  };

  return (
    <>
      <ToastMessage />
      <Modal
        className="Modal"
        isOpen={props.modalIsOpen}
        onRequestClose={() => {
          props.setModalIsOpen(false);
        }}
        ariaHideApp={false}
        style={customStyles}
      >
        <div className="modalBody font-workSans mt-[10rem]">
          <div className="editNoticeModal rounded-xl w-[50%] mx-auto">
            <div className="w-[90%] mx-auto my-[7%]">
              <div className="text-5xl font-black">
                {props.modalNoticeData.title}
              </div>
              <div className="mt-[1rem]">
                {' '}
                <div className="text-sm font-black">
                  {language === false ? '작성일' : 'Date'}
                </div>
                <div className="text-darkGrayColor">
                  {props.modalNoticeData.created_date}
                </div>
              </div>
              <div className="mt-[0.7rem]">
                <div className="text-sm font-black">
                  {language === false ? '작성자' : 'Created By'}
                </div>
                <div className="text-darkGrayColor">
                  {props.modalNoticeData.created_by}
                </div>
              </div>
              <div className="mt-[0.7rem]">
                <div className="text-lg font-black text-green">
                  {language === false ? '내용' : 'Content'}
                </div>
                <textarea
                  className="textarea text-black w-[100%] h-[10rem] rounded-xl mt-[0.5rem]"
                  placeholder={props.modalNoticeData.content}
                  onChange={saveNewContent}
                />
              </div>
              <div className="">
                <button className="w-[100%] h-[2rem] bg-green rounded-full hover:bg-darkGreen transition delay-100 drop-shadow-md mt-[2rem]">
                  <div className="px-[1rem]" onClick={modifyContentClick}>
                    수정
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AdminEditNoticeContentModalPc;
