// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';

// css
import '../../assets/css/noticeCardPc.css';

function ContactPc() {
  const googleQA =
    'https://docs.google.com/forms/d/1yWvQVOCJYYd_RwmtrEE5UmrUKchCwubn0t57Uvd3UYo/viewform?edit_requested=true#responses';

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      {/* Contact */}
      <div className="mt-[10rem]">
        <div className="font-bold text-2xl text-center">
          <div>For inquiries and business collaborations,</div>
          <div>please contact us at the email address below.</div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="w-[25rem] h-[3px] bg-primaryColor mt-[2.5rem]"></div>
      </div>

      {/* Email */}
      <div className="flex justify-center mt-[2.5rem]">
        <div className="contact text-2xl py-[0.5rem] px-[1.7rem] rounded-full">
          shs.block@shinhan.com
        </div>
      </div>
    </>
  );
}

export default ContactPc;
