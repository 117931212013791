// modules
import React from 'react';

function LoginState(props) {
  return (
    <>
      {props.state === true ? (
        <>
          <div className="w-[18%] h-[2rem] mx-auto text-black">
            <div className="bg-logInColor rounded-full text-center font-semibold">
              Logged in now 🙆‍♂️
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-[18%] h-[2rem] mx-auto">
            <div className="bg-logOutColor rounded-full text-center font-semibold">
              Logged out now 🙅‍♂️
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LoginState;
