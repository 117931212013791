// modules
import React from 'react';

// css

function TeamCardSkeletonPc() {
  return (
    <div className="font-workSans">
      <div className="flex flex-col gap-4 w-[17.5rem]">
        <div className="skeleton h-[25rem] w-full"></div>
      </div>
    </div>
  );
}

export default TeamCardSkeletonPc;
