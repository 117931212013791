// modules
import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// components
import LoginState from '../../components/pc/LoginStatePc';
import LogoutButton from '../../components/pc/LogoutButton';
import Loading from '../../components/pc/LoadingPc';
import AdminUpFileDropzonePc from '../../components/pc/AdminUpFileDropzonePc';
import ToastMessage from '../../components/pc/ToastMessagePc';

// css
import '../../assets/css/adminPostNotice.css';

//APIs
import { getLoginState, getLogout } from '../../apis/request';

function AdminUpFilePc() {
  // login 상태관리
  const [loggedIn, setLoggedIn] = useState(false);

  // loading 상태관리
  const [loding, setLoding] = useState(true);

  // 업로드 파일 이름 저장
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    const loginState = getLoginState();
    const getAPIData = () => {
      loginState
        .then((response) => {
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            setLoggedIn(true);
          }
          setLoding(false);
        });
    };

    getAPIData();
  }, []);

  // Toast Message 성공 알람, 실패 알람
  const successUpFile = () =>
    toast.success(' 회의록 업로드 완료 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorUpfile = (message) =>
    toast.error(' 회의록 업로드 실패 ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const nothingError = (message) =>
    toast.error(' 파일을 업로드하세요 ! ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const notPDFError = (message) =>
    toast.error(' pdf 파일만 업로드 할 수 있습니다 ! ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const successLogout = () =>
    toast.success(' 로그아웃 성공 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorLogout = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorNotLogin = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  const logOutClick = (event) => {
    const getAPIData = () => {
      getLogout()
        .then((response) => {
          console.log(response);
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            if (loggedIn === false) {
              errorNotLogin('현재 로그인 상태가 아닙니다');
            } else {
              setLoggedIn(false);
              successLogout();
            }
          } else {
            errorLogout(data);
          }
        });
    };

    getAPIData();
  };

  return (
    <>
      <ToastMessage />
      <div className="mt-[10rem]">
        {loding ? (
          <div className=" mx-auto flex justify-center">
            <Loading />
          </div>
        ) : (
          <LoginState state={loggedIn} />
        )}
        <div>
          <LogoutButton logOutClick={logOutClick} />
        </div>
      </div>
      <div className="w-screen font-workSans my-[20rem]">
        <div className="postNoticeCard rounded-xl mt-[1.5rem] w-[40%] mx-auto">
          <div className="w-[90%] mx-auto my-[3%]">
            <div className="text-xl font-black">File (only accept .pdf)</div>
            <div className="">
              <div className="text-lightGrayColor">{fileName}</div>
              <AdminUpFileDropzonePc
                setFileName={setFileName}
                success={successUpFile}
                error={errorUpfile}
                nothingError={nothingError}
                notPDFError={notPDFError}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminUpFilePc;
