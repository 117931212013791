// modules
import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// css
import '../../assets/css/adminPostNotice.css';

// APIs
import { getLoginState, getLogout } from '../../apis/request';

// components
import LoginState from '../../components/pc/LoginStatePc';
import LogoutButton from '../../components/pc/LogoutButton';
import Loading from '../../components/pc/LoadingPc';
import ToastMessage from '../../components/pc/ToastMessagePc';

function AdminRouterPc() {
  // login 상태관리
  const [loggedIn, setLoggedIn] = useState(false);

  // loading 상태관리
  const [loding, setLoding] = useState(true);

  // Toast Message 성공 알람, 실패 알람
  const successLogout = () =>
    toast.success(' 로그아웃 성공 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorLogout = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorNotLogin = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  const logOutClick = (event) => {
    const getAPIData = () => {
      getLogout()
        .then((response) => {
          console.log(response);
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            if (loggedIn === false) {
              errorNotLogin('현재 로그인 상태가 아닙니다');
            } else {
              setLoggedIn(false);
              successLogout();
            }
          } else {
            errorLogout(data);
          }
        });
    };

    getAPIData();
  };

  useEffect(() => {
    const loginState = getLoginState();
    const getAPIData = () => {
      loginState
        .then((response) => {
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            setLoggedIn(true);
          }
          setLoding(false);
        });
    };

    getAPIData();
  }, []);

  return (
    <>
      <ToastMessage />
      <div className="mt-[10rem]">
        {loding ? (
          <div className=" mx-auto flex justify-center">
            <Loading />
          </div>
        ) : (
          <LoginState state={loggedIn} />
        )}
        <div>
          <LogoutButton logOutClick={logOutClick} />
        </div>
      </div>
      <div className="w-screen font-workSans py-[8rem] my-[3rem]">
        <div className="postNoticeCard rounded-xl mt-[1.5rem] w-[30%] mx-auto hover:bg-darkGrayColor transition delay-100 drop-shadow-md font-bold">
          <Link to="/postNotice">
            <div className="w-[90%] mx-auto my-[3%]">Post Notice</div>
          </Link>
        </div>
        <div className="postNoticeCard rounded-xl mt-[1.5rem] w-[30%] mx-auto hover:bg-darkGrayColor transition delay-100 drop-shadow-md font-bold">
          <Link to="/editNotice">
            <div className="w-[90%] mx-auto my-[3%]">Edit Notice</div>
          </Link>
        </div>
        <div className="postNoticeCard rounded-xl mt-[1.5rem] w-[30%] mx-auto hover:bg-darkGrayColor transition delay-100 drop-shadow-md font-bold">
          <Link to="/uploadFile">
            <div className="w-[90%] mx-auto my-[3%]">Upload Minutes</div>
          </Link>
        </div>
        <div className="postNoticeCard rounded-xl mt-[1.5rem] w-[30%] mx-auto hover:bg-darkGrayColor transition delay-100 drop-shadow-md font-bold">
          <Link to="/editMeeting">
            <div className="w-[90%] mx-auto my-[3%]">Edit Minutes</div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default AdminRouterPc;
