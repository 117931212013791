// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';

// css
import '../../assets/css/noticeCardPc.css';

function AdminNoticeCardPc(props) {
  // 버튼 클릭시 클락한 공지사항의 데이터를 저장 (상태 끌어올리기)
  const noticeTitleClick = () => {
    props.setModalNoticeData({
      title: props.title,
      created_date: props.created_date,
      created_by: props.created_by,
      content: props.content,
    });

    // 버튼 클릭시 Title을 수정하는 Modal이 열릴 수 있도록 상태제어
    props.setEditTitleModalIsOpen(true);
  };

  const noticeContentClick = () => {
    props.setModalNoticeData({
      title: props.title,
      created_date: props.created_date,
      created_by: props.created_by,
      content: props.content,
    });

    // 버튼 클릭시 Content을 수정하는 Modal이 열릴 수 있도록 상태제어
    props.setEditContentModalIsOpen(true);
  };

  // jotai를 통해 language의 전역 상태값을 저장하고 관리합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <>
      <div className="noticeCard rounded-xl mt-[1.5rem]">
        <div className="w-[85%] mx-auto my-[8%]">
          <div className="w-[100%] text-2xl font-black truncate">
            {props.title}
          </div>
          <div className="flex mt-[1rem] mb-[0.5rem]">
            <span className="w-[50%]">
              <div className="text-xs font-black">
                {language === false ? '작성일' : 'Date'}
              </div>
              <div className="text-xs text-darkGrayColor mt-[0.1rem]">
                {props.created_date}
              </div>
            </span>
            <span className="w-[50%">
              <div className="text-xs font-black">
                {language === false ? '작성자' : 'Created by'}
              </div>
              <div className="text-xs text-darkGrayColor mt-[0.1rem]">
                {props.created_by}
              </div>
            </span>
          </div>
          <div className="font-black text-base mt-[1rem]">
            {language === false ? '내용' : 'Content'}
          </div>
          <div className="w-[100%] h-[11rem] text-sm text-lightGrayColor overflow-hidden">
            {props.content}
          </div>
          <div className="flex justify-center">
            <button
              className="w-[47%] h-[2rem] mx-auto bg-green mt-[1rem] rounded-full hover:bg-darkGreen transition delay-100 drop-shadow-md font-bold hover:-translate-y-1"
              onClick={noticeTitleClick}
            >
              <div>제목수정</div>
            </button>
            <button
              className="w-[47%] h-[2rem] mx-auto bg-green mt-[1rem] rounded-full hover:bg-darkGreen transition delay-100 drop-shadow-md font-bold hover:-translate-y-1"
              onClick={noticeContentClick}
            >
              <div>내용수정</div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminNoticeCardPc;
