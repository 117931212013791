// modules
import React from 'react';
import { useEffect, useState } from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { toast } from 'react-toastify';

// components
import Pagination from '../../components/pc/Pagination';
import AdminMeetingCardPc from '../../components/pc/AdminMeetingCardPc';
import Loading from '../../components/pc/LoadingPc';
import LoginState from '../../components/pc/LoginStatePc';
import LogoutButton from '../../components/pc/LogoutButton';
import ToastMessage from '../../components/pc/ToastMessagePc';
import AdminEditMeetingTitleModalPc from '../../components/pc/AdminEditMeetingTitleModalPc';

// css
import '../../assets/css/noticePc.css';

//APIs
import {
  getMeeting,
  getMeetingCount,
  getMeetingByTitle,
  getLoginState,
  getLogout,
} from '../../apis/request';
import { deleteMeetingData } from '../../apis/delete';

//image

function AdminEditMeetingPc() {
  //Meeting Information
  const [meetings, setMeetings] = useState([]);

  // 전체 Meeting 갯수를 저장합니다.
  const [meetingLength, setMeetingLength] = useState('');

  // 하나의 page에 들어갈 데이터를 저장합니다
  const [page, setPage] = useState(1); // 현재 페이지를 저장합나다.
  const handlePageChange = (page) => {
    // pagination 버튼이 클릭되면 해당 함수가 작동되어 현재 page를 저장합니다.
    setPage(page);
    // 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
    window.scrollTo(0, 0);
  };
  const limit = 10; // posts가 보일 최대한의 갯수

  // input 값을 저장합니다
  const [input, setInput] = useState('');

  // search값을 실시간으로 저장합니다
  const saveSearch = (event) => {
    setInput(event.target.value);
  };

  // login 상태관리
  const [loggedIn, setLoggedIn] = useState(false);

  // loading 상태관리
  const [loding, setLoding] = useState(true);

  // search값으로 notice를 조회합니다
  const searchClick = (event) => {
    const getData = () => {
      getMeetingByTitle(input)
        .then((response) => {
          return response.data.resources;
        })
        .then((data) => {
          setMeetings(data);
          setMeetingLength(data.length);
        });
    };
    getData();
  };

  //search 값의 enter를 감지하고 enter를 통해 검색을 실행합니다
  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchClick(event);
    }
  };

  // Toast Message 성공 알람, 실패 알람
  const successDeleteMeeting = () =>
    toast.success(' 회의록 삭제 완료 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorDeleteMeeting = () =>
    toast.error(' 회의록 삭제 실패 ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const successLogout = () =>
    toast.success(' 로그아웃 성공 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorLogout = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorNotLogin = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  // input값으로 공지사항을 delete합니다
  const deleteClick = (event) => {
    const deleteData = () => {
      deleteMeetingData(input)
        .then((response) => {
          return response;
        })
        .then((response) => {
          if (response.data.success === true) {
            successDeleteMeeting();
            setInput('');
          } else {
            errorDeleteMeeting();
          }
        });
    };
    deleteData();
  };

  const logOutClick = (event) => {
    const getAPIData = () => {
      getLogout()
        .then((response) => {
          console.log(response);
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            if (loggedIn === false) {
              errorNotLogin('현재 로그인 상태가 아닙니다');
            } else {
              setLoggedIn(false);
              successLogout();
            }
          } else {
            errorLogout(data);
          }
        });
    };

    getAPIData();
  };

  useEffect(() => {
    const meetingData = getMeeting(page);
    const lengthData = getMeetingCount();
    const getAPIData = () => {
      meetingData
        .then((response) => {
          setMeetings(response.data.resources);

          return response.data.resources;
        })
        .then((result) => {
          if (result) {
            setLoding(false);
          }
        });

      lengthData.then((response) => {
        setMeetingLength(response.data.count);

        return response.data.count;
      });
    };

    getAPIData();
  }, [page]);

  useEffect(() => {
    const loginState = getLoginState();
    const getAPIData = () => {
      loginState
        .then((response) => {
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            setLoggedIn(true);
          }
          setLoding(false);
        });
    };

    getAPIData();
  }, []);

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // modal 상태 제어
  const [editTitleModalIsOpen, setEditTitleModalIsOpen] = useState(false);

  // modal에 들어갈 데이터 저장
  const [modalMeetingData, setModalMeetingData] = useState({
    title: '',
    created_date: '',
    created_by: '',
  });

  return (
    <>
      <ToastMessage />
      <div className="mt-[10rem]">
        {loding ? (
          <div className=" mx-auto flex justify-center">
            <Loading />
          </div>
        ) : (
          <LoginState state={loggedIn} />
        )}
        <div>
          <LogoutButton logOutClick={logOutClick} />
        </div>
      </div>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div className="w-screen font-workSans mt-[8rem]">
          <div className="w-[80%] mx-auto text-7xl font-black">
            {language === false ? '회의록' : 'Meetings'}
          </div>
          <div className="w-[80%] mx-auto my-[1rem] text-xl">
            <span className="">
              {language === false ? '얼라이언스로부터' : 'Get'}
            </span>
            <span className="text-primaryColor">
              &nbsp;{language === false ? '회의록을' : 'records'}
            </span>
            <span className="">
              &nbsp;{language === false ? '받으세요' : 'from our alliance.'}
            </span>
          </div>
          <div className="w-[80%] mx-auto text-darkGrayColor">
            {language === false
              ? '얼라이언스 회원들 간의 효과적인 의사소통을 통해 정기적인 회의를 진행합니다.'
              : 'We have regular offline meetings through effective communication among Alliance members.'}
          </div>

          {/* search */}
          <div className="w-screen font-workSans mt-[3rem] mb-[1rem] font-workSans">
            <div className="w-[80%] mx-auto">
              <div className="w-[40%] h-[3rem] bg-white rounded-l-full relative">
                <input
                  className="w-[80%] h-[3rem] ml-[5%] rounded-full"
                  placeholder="Text"
                  onChange={saveSearch}
                  onKeyPress={handleOnKeyPress}
                />
                <div className="search h-[3rem] bg-green rounded-full absolute hover:bg-darkGreen transition delay-100">
                  <button
                    className="px-[1rem] py-[0.7rem] font-black"
                    onClick={searchClick}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="w-screen font-workSans mt-[1rem] mb-[1rem] font-workSans">
            <div className="w-[80%] mx-auto">
              <div className="w-[40%] h-[3rem] bg-white rounded-l-full relative">
                <input
                  className="w-[80%] h-[3rem] ml-[5%] rounded-full"
                  placeholder="Input the title you want to delete"
                  onChange={saveSearch}
                  onKeyPress={handleOnKeyPress}
                />
                <div className="search h-[3rem] bg-red rounded-full absolute hover:bg-darkRed transition delay-100">
                  <button
                    className="px-[1rem] py-[0.7rem] font-black"
                    onClick={deleteClick}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* meeting cards */}
          {loding ? (
            <div className="my-auto">
              <div className="mx-auto my-[15rem] flex justify-center">
                <Loading />
              </div>
            </div>
          ) : (
            <div className="w-[80%] mx-auto mt-[3rem]">
              {meetings.map((meeting) => {
                return (
                  <div key={meeting.object_key}>
                    <AdminMeetingCardPc
                      title={meeting.title}
                      created_date={meeting.created_date}
                      created_by={meeting.created_by}
                      object_key={meeting.object_key}
                      setModalMeetingData={setModalMeetingData}
                      setEditTitleModalIsOpen={setEditTitleModalIsOpen}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {/* Modal */}
          <div>
            <AdminEditMeetingTitleModalPc
              modalMeetingData={modalMeetingData}
              modalIsOpen={editTitleModalIsOpen}
              setModalIsOpen={setEditTitleModalIsOpen}
            />
          </div>

          <div className="mt-[5rem]">
            <Pagination
              page={page}
              limit={limit}
              totalPosts={Number(meetingLength)}
              handlePageChange={handlePageChange} //현재 페이지의 위치를 설정하는 handlePageChange props로 넘긴다
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminEditMeetingPc;
