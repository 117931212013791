// modules
import axios from 'axios';

// URL
import config from '../config/config';

export async function patchNoticeTitleData(title, new_title) {
  try {
    const requestURL = `${config.apiURL}/announcement/title`;

    const response = await axios.patch(requestURL, {
      title: title,
      new_title: new_title,
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function patchNoticeContentData(title, new_content) {
  try {
    const requestURL = `${config.apiURL}/announcement/content`;

    const response = await axios.patch(requestURL, {
      title: title,
      content: new_content,
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function patchMeetingTitleData(title, new_title) {
  try {
    const requestURL = `${config.apiURL}/report/title`;

    const response = await axios.patch(requestURL, {
      title: title,
      new_title: new_title,
    });
    return response;
  } catch (e) {
    return e;
  }
}
