// modules
import React from 'react';
import { useLocation } from 'react-router-dom';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';

// components

// css
import '../../assets/css/noticeDetails.css';

//APIs

//image

function NoticeDetailsPc() {
  // Notice페이지로부터 전달받은 데이터를 저장합니다.
  const location = useLocation();

  const title = location.state.title;
  const created_date = location.state.created_date;
  const created_by = location.state.created_by;
  const content = location.state.content;
  // const object_key = location.state.object_key;

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <div style={{ backgroundColor: '#000000' }} className="font-workSans">
      <div className="w-screen font-workSans pt-[2rem] mt-[2rem]">
        <div className="noticeDetailsCard rounded-xl mt-[1.5rem] w-[90%] mx-auto">
          <div className="w-[90%] mx-auto my-[7%]">
            <div className="text-3xl font-black">{title}</div>
            <div className="mt-[1rem]">
              {' '}
              <div className="text-sm font-black">
                {language === false ? '작성일' : 'Date'}
              </div>
              <div className="text-darkGrayColor">{created_date}</div>
            </div>
            <div className="mt-[0.7rem]">
              <div className="text-sm font-black">
                {language === false ? '작성자' : 'Created By'}
              </div>
              <div className="text-darkGrayColor">{created_by}</div>
            </div>
            <div className="mt-[0.7rem]">
              <div className="text-lg font-black text-primaryColor">
                {language === false ? '내용' : 'Content'}
              </div>
              <div className=""> {content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeDetailsPc;
