import React from 'react';
import { ProgressBar } from 'react-loader-spinner';

function LodingPc() {
  return (
    <ProgressBar
      height="60"
      width="60"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{}}
      wrapperClass="progress-bar-wrapper"
      borderColor="#C147E9"
      barColor="#ECF2FF"
    />
  );
}
export default LodingPc;
