// modules
import axios from 'axios';

// URL
import config from '../config/config';

export async function deleteNoticeData(title) {
  try {
    const requestURL = `${config.apiURL}/announcement`;

    const response = await axios.delete(requestURL, {
      data: { title: title },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function deleteMeetingData(title) {
  try {
    const requestURL = `${config.apiURL}/report`;

    const response = await axios.delete(requestURL, {
      data: { title: title },
    });
    return response;
  } catch (e) {
    return e;
  }
}
