// modules
import React from 'react';
import { useEffect, useState } from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// components
import Pagination from '../../components/pc/Pagination';
import MeetingCardMo from '../../components/mobile/MeetingCardMo';
import Loading from '../../components/pc/LoadingPc';

// css
import '../../assets/css/meetings.css';

//APIs
import {
  getMeeting,
  getMeetingCount,
  getMeetingByTitle,
} from '../../apis/request';

//image

function PressMo() {
  //Meeting Information
  const [meetings, setMeetings] = useState([]);

  // 전체 Meeting 갯수를 저장합니다.
  const [meetingLength, setMeetingLength] = useState('');

  // 하나의 page에 들어갈 데이터를 저장합니다
  const [page, setPage] = useState(1); // 현재 페이지를 저장합나다.
  const handlePageChange = (page) => {
    // pagination 버튼이 클릭되면 해당 함수가 작동되어 현재 page를 저장합니다.
    setPage(page);
    // 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
    window.scrollTo(0, 0);
  };
  const limit = 10; // posts가 보일 최대한의 갯수

  // search 값을 저장합니다
  const [search, setSearch] = useState('');

  // search값을 실시간으로 저장합니다
  const saveSearch = (event) => {
    setSearch(event.target.value);
  };

  // loading 상태관리
  const [loding, setLoding] = useState(true);

  // search값으로 notice를 조회합니다
  const searchClick = (event) => {
    const getData = () => {
      getMeetingByTitle(search)
        .then((response) => {
          return response.data.resources;
        })
        .then((data) => {
          setMeetings(data);
          setMeetingLength(data.length);
        });
    };
    getData();
  };

  //search 값의 enter를 감지하고 enter를 통해 검색을 실행합니다
  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchClick(event);
    }
  };

  useEffect(() => {
    const meetingData = getMeeting(page);
    const lengthData = getMeetingCount();
    const getAPIData = () => {
      meetingData
        .then((response) => {
          setMeetings(response.data.resources);

          return response.data.resources;
        })
        .then((result) => {
          if (result) {
            setLoding(false);
          }
        });

      lengthData.then((response) => {
        setMeetingLength(response.data.count);

        return response.data.count;
      });
    };

    getAPIData();
  }, [page]);

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Dataset',
              url: 'https://shinhandigitalasset.com/meetings',
              name: '회의록',
              description:
                '얼라이언스 회원들 간의 효과적인 의사소통을 통해 정기적인 회의를 진행합니다.',
              distribution: {
                '@type': 'DataDownload',
                encodingFormat: 'pdf',
              },
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div className="w-screen font-workSans mt-[6rem]">
          <div className="w-[80%] mx-auto text-4xl font-black">
            {language === false ? 'PressMo' : 'Meetings'}
          </div>
          <div className="w-[80%] mx-auto my-[1rem] text-base">
            <span className="">
              {language === false ? '얼라이언스로부터' : 'Get'}
            </span>
            <span className="text-primaryColor">
              &nbsp;{language === false ? '회의록을' : 'records'}
            </span>
            <span className="">
              &nbsp;{language === false ? '받으세요' : 'from our alliance.'}
            </span>
          </div>
          <div className="w-[80%] mx-auto text-darkGrayColor text-xs">
            {language === false
              ? '얼라이언스 회원들 간의 효과적인 의사소통을 통해 정기적인 회의를 진행합니다.'
              : 'We have regular offline meetings through effective communication among Alliance members.'}
          </div>

          {/* search */}
          <div className="w-screen font-workSans mt-[1.5rem] mb-[1rem] font-workSans">
            <div className="w-[80%] mx-auto">
              <div className="w-[40%] h-[2rem] bg-white rounded-l-full relative">
                <input
                  className="w-[80%] h-[2rem] ml-[5%] rounded-full"
                  placeholder="Text"
                  onChange={saveSearch}
                  onKeyPress={handleOnKeyPress}
                />
                <div className="search h-[2rem] bg-primaryColor rounded-full absolute hover:bg-primaryColorDark transition delay-100">
                  <button
                    className="px-[0.7rem] py-[0.5rem] font-black text-[10px]"
                    onClick={searchClick}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* meeting cards */}
          {loding ? (
            <div className="my-auto">
              <div className="mx-auto my-[15rem] flex justify-center">
                <Loading />
              </div>
            </div>
          ) : (
            <div className="w-[80%] mx-auto mt-[2rem]">
              {meetings.map((meeting) => {
                return (
                  <div key={meeting.object_key}>
                    <MeetingCardMo
                      title={meeting.title}
                      created_date={meeting.created_date}
                      created_by={meeting.created_by}
                      download_link={meeting.download_link}
                      object_key={meeting.object_key}
                    />
                  </div>
                );
              })}
            </div>
          )}

          <div className="mt-[5rem]">
            <Pagination
              page={page}
              limit={limit}
              totalPosts={Number(meetingLength)}
              handlePageChange={handlePageChange} //현재 페이지의 위치를 설정하는 handlePageChange props로 넘긴다
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PressMo;
