// modules
import React from 'react';

// css
import '../../assets/css/noticeCardPc.css';

function SpeakCardPcMo(props) {
  return (
    <>
      <div className="flex-col">
        <img
          alt="man"
          src={props.man}
          className="w-[10rem] h-[10rem] mx-auto rounded-full"
        ></img>
        <div className="flex flex-col-reverse text-center text-xs mt-[1rem]">
          <div className="text-darkGrayColor">{props.position}</div>
          <div>{props.name}</div>
        </div>

        <div className="mt-[1rem] text-center text-sm">{props.text}</div>
      </div>
    </>
  );
}

export default SpeakCardPcMo;
