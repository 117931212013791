// modules
import axios from 'axios';

// URL
import config from '../config/config';

export async function postNoticeData(title, content) {
  try {
    const requestURL = `${config.apiURL}/announcement`;

    const response = await axios.post(requestURL, {
      title: title,
      content: content,
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function postPassportData(username, password) {
  try {
    const requestURL = `${config.apiURL}/auth/login`;

    const response = await axios.post(requestURL, {
      username: username,
      password: password,
    });
    return response;
  } catch (e) {
    return e;
  }
}

// dropzone post file
export async function postReportData(formData) {
  try {
    const requestURL = `${config.apiURL}/report`;

    const response = await axios.post(requestURL, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  } catch (e) {
    return e;
  }
}
