// modules
import React from 'react';

function MeetingCard(props) {
  return (
    <>
      <div className="meetingCard rounded-xl mt-[0.5rem]">
        <div className="py-[1rem] px-[2rem] flex">
          <div className="text-xl">
            <div className="font-extrabold">{props.title}</div>
            <div className="flex mt-[0.5rem]">
              <div className="flex items-end">
                <div className="text-base">Date</div>
                <div className="ml-[0.5rem] text-sm text-center text-darkGrayColor">
                  {props.created_date}
                </div>
              </div>
              <div className="flex items-end ml-[2.5rem]">
                <div className="text-base">Created by</div>
                <div className="ml-[0.5rem] text-sm text-darkGrayColor">
                  {props.created_by}
                </div>
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="h-[100%] flex items-center">
              <button className="w-[100%] bg-primaryColor rounded-full hover:bg-primaryColorDark transition delay-100 drop-shadow-md font-bold">
                <div className="px-[1rem] text-lg">
                  <a href={props.download_link}>Download</a>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeetingCard;
