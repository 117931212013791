// modules
import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// css
import '../../assets/css/noticeDetails.css';
import '../../assets/css/adminPostNotice.css';

// APIs
import { postPassportData } from '../../apis/post';
import { getLoginState, getLogout } from '../../apis/request';

// components
import LoginState from '../../components/pc/LoginStatePc';
import LogoutButton from '../../components/pc/LogoutButton';
import Loading from '../../components/pc/LoadingPc';
import ToastMessage from '../../components/pc/ToastMessagePc';

function AdminPassportAuthPc() {
  // User가 서버로 post요청을 보낼 데이터를 저장합니다
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  // input태그의 id, password를 실시간으로 저장합니다
  const saveId = (event) => {
    setId(event.target.value);
  };
  const savePassword = (event) => {
    setPassword(event.target.value);
  };

  // login 상태관리
  const [loggedIn, setLoggedIn] = useState(false);

  // loading 상태관리
  const [loding, setLoding] = useState(true);

  // Toast Message 성공 알람, 실패 알람
  const successLogin = () =>
    toast.success(' 로그인 성공 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorLogin = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const successLogout = () =>
    toast.success(' 로그아웃 성공 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorLogout = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorNotLogin = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  const logInClick = (event) => {
    const postData = () => {
      postPassportData(id, password)
        .then((response) => {
          return response;
        })
        .then((authenticated) => {
          if (authenticated.data.success === true) {
            setLoggedIn(true);
            successLogin();
          } else {
            errorLogin(authenticated.data);
          }
        });
    };

    postData();
  };

  const logOutClick = (event) => {
    const getAPIData = () => {
      getLogout()
        .then((response) => {
          console.log(response);
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            if (loggedIn === false) {
              errorNotLogin('현재 로그인 상태가 아닙니다');
            } else {
              setLoggedIn(false);
              successLogout();
            }
          } else {
            errorLogout(data);
          }
        });
    };

    getAPIData();
  };

  useEffect(() => {
    const loginState = getLoginState();
    const getAPIData = () => {
      loginState
        .then((response) => {
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            setLoggedIn(true);
          }
          setLoding(false);
        });
    };

    getAPIData();
  }, []);

  return (
    <>
      <ToastMessage />
      <div className="mt-[10rem]">
        {loding ? (
          <div className="mx-auto flex justify-center">
            <Loading />
          </div>
        ) : (
          <LoginState state={loggedIn} />
        )}
        <div>
          <LogoutButton logOutClick={logOutClick} />
        </div>
      </div>
      <>
        {loggedIn === true ? (
          <div className="w-screen h-screen font-workSans py-[8rem] mt-[3rem]">
            <div className="postNoticeCard rounded-xl mt-[1.5rem] w-[18%] mx-auto hover:bg-darkGrayColor transition delay-100 drop-shadow-md font-bold">
              <Link to="/adminRouter">
                <div className="w-[90%] mx-auto my-[3%]">Admin</div>
              </Link>
            </div>
          </div>
        ) : (
          <div className="w-screen h-screen font-workSans py-[5rem]">
            <div className="w-[20%] mx-auto mt-[5rem]">
              <div className="text-xl mb-[0.5rem]">ID, Password</div>
              <input
                type="id"
                className="bg-white rounded-full w-[100%] h-[2rem]"
                placeholder=""
                onChange={saveId}
              />
              <input
                type="password"
                className="bg-white rounded-full w-[100%] h-[2rem] mt-[0.5rem]"
                placeholder=""
                onChange={savePassword}
              />
              <div className="">
                <button className="inline-block float-right mt-[1rem] bg-primaryColor rounded-full hover:bg-primaryColorDark transition delay-100 drop-shadow-md bg-primaryColor rounded-2xl font-bold">
                  <div className="px-[1rem] py-[0.2rem]" onClick={logInClick}>
                    Log in
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
}

export default AdminPassportAuthPc;
