// modules
import React from 'react';

// css
import '../../assets/css/noticeCardPc.css';

function SpeakCardPc(props) {
  return (
    <>
      <div className="flex">
        <img
          alt="man"
          src={props.man}
          className="w-[10rem] h-[10rem] rounded-full"
        ></img>

        <div className="w-[65%] ml-[2rem]">{props.text}</div>
        <div className="flex flex-col-reverse w-[20%] ml-[2rem] text-right">
          <div className="text-darkGrayColor">{props.position}</div>
          <div>{props.name}</div>
        </div>
      </div>
    </>
  );
}

export default SpeakCardPc;
