// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';

// css
import '../../assets/css/teamCardMo.css';

function TeamCardPc(props) {
  // jotai를 통해 language의 전역 상태값을 저장하고 관리합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <div className="font-workSans">
      <div className="wrapper w-[17.5rem] h-[25rem]">
        <div className="card">
          {/* front card */}
          <div className="front flex-col">
            <div className="relative">
              <div className="">
                <img
                  alt="teamMan"
                  src={props.image}
                  className="h-[100%] h-[100%] bg-gradient-to-top from-black"
                ></img>
              </div>
              <div className="absolute bottom-[2rem] right-[1rem] text-right">
                <div className="text-3xl font-bold">{props.frontName}</div>
                <div className="font-semibold text-primaryColor">
                  {props.position}
                </div>
              </div>
            </div>
          </div>
          {/* back card */}
          <div className="back py-[1.5rem] px-[1rem] text-left">
            <div className="">
              <div className="text-2xl font-bold text-primaryColor">
                {props.backName}
              </div>
              <div className="text-xs font-semibold text-lightGrayColor">
                {props.position}
              </div>
            </div>
            <div className="mt-[0.5rem]">
              <div className="text-xl font-bold">Comment</div>
              <div className="text-sm text-lightGrayColor">{props.comment}</div>
            </div>
            <div className="mt-[0.5rem]">
              <div className="text-xl font-bold">Contact</div>
              <div className="text-sm text-lightGrayColor">{props.contact}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamCardPc;
