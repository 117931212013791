const aspect = 1.2;
const cameraZ = 300;

const container = () => document.getElementById('scene-container');

// const canvasWidth = () =>
//   window?.innerWidth > 1440
//     ? container()?.offsetHeight ?? 500
//     : window.innerWidth;

// const canvasWidth = () => window.innerWidth * 0.7;
const canvasWidth = () => {
  if (window.innerWidth >= 769) {
    return 560;
  } else {
    return 380;
  }
};

const canvasHeight = () => canvasWidth() / aspect;

// const canvasHeight = () => container()?.offsetHeight ?? 500;
// const canvasWidth = () => canvasHeight() * aspect;

export { aspect, cameraZ, canvasWidth, canvasHeight };
