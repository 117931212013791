// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// svg image
import { ReactComponent as SandboxServiceSVG } from '../../assets/images/svgImage/SandboxInfra_service.svg';

// components
import DisclaimerPc from '../../components/pc/DisclaimerPc';
import ContactPc from '../../components/pc/ContactPc';

// css
import '../../assets/css/sandBoxCardPc.css';

// image(temporary)
const Slide1 = require('../../assets/images/sandboxImg1.webp');
const Slide2 = require('../../assets/images/sandboxImg2.webp');

function SandboxPc() {
  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://shinhandigitalasset.com/sandbox',
              name: 'Project Pulse',
              description:
                '경쟁력 있고 검증된 STO 금융 분산원장 인프라를 초기 구축비용 최소화하여 지원하고 규제변화에 발맞춰 고객사가 지속적으로 사업을 영위할 수 있도록 확장성 높은 인프라 서비스를 제공',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '서울 영등포구 여의도동 의사당대로 96',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'shs.block@shinhan.com',
                  contactType: 'email',
                },
              ],
              keywords: [
                '토큰증권',
                '가상자산',
                '비트코인',
                '블록체인',
                '금융위원회',
                '얼라이언스',
                '신한투자증권',
                '유진투자증권',
                '미래에셋',
                'KB증권',
                'NH증권',
                '한국투자증권',
                '키움증권',
                '삼성증권',
                'SK증권',
                'STO얼라이언스',
                'NFI',
                '넥스트 파이낸스 이니셔티브',
                'Next Finance Initiative',
                'ST 오너스',
                'ST 프렌즈',
                'STO 비전그룹',
                'STO',
                'Security Token',
                'Crypto',
                'Bitcoin',
                'Blockchain',
                'STO Alliance',
                '투자계약증권',
                '비금전신탁수익증권',
                'Securities',
                'Shinhan',
                'Token',
                'Bitcoin etf',
                'Bitcoin spot etf',
                '블록체인 인프라',
                '분산원장',
                'Project Pulse',
                '프로젝트 펄스',
                'RWA',
                '조각투자',
                'Investment',
                'Investment Contract',
                'Non-monetary Trust Beneficiary Certificates',
                'Fragment Investment',
                'Digital Asset',
                'Digital Wallet',
                'Web3',
                'Smart Contract',
                'Blockchain Global',
                '신종증권',
                '전자증권',
                'Digital',
                'ETF',
                'NFT',
                '혁신금융서비스',
                '조각투자사업자',
                '증권신고서',
                '예치금자금관리',
                '투자자보호기금',
                '에이판다',
                '발행시장',
                '유통시장',
                'Secondary Market',
                '서울옥션블루',
                '갤럭시아머니트리',
                '열매컴퍼니',
                '미술품 조각투자',
                '금융 솔루션',
                'Financial Advisory Service',
                'Business Consulting',
                'STO Platform',
                'STO 플랫폼',
                '증권업계',
                '매출채권',
                '유동화',
                '투자자문',
                '사업구조 설계',
                '증권구조',
                '드웰파이',
                'DwellFi',
                'Franklin Templeton',
                '금융 컨설팅',
                '유가증권',
                'Financial Institution',
                'Korean Financial Institution',
                'Korea Digital Asset',
                'Ethereum',
              ],
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '08:00:00',
                },
              ],
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div className="w-screen mt-[8rem]">
          {/* Sandbox Description*/}
          <div className="w-[70%] mx-auto">
            <div className="text-5xl w-[100%] mb-[2rem] font-bold">
              <span className="text-primaryColor">Blockchain Infra </span>
              Service
            </div>
            <div>
              <div className="flex">
                <SandboxServiceSVG className="w-[60rem] my-auto" />
                <div className="ml-[1rem]">
                  <div className="">
                    <div className="text-2xl font-bold">Project Pulse란?</div>
                    <div className="text-lightGrayColor">
                      프로젝트 펄스는 금융분야에서 블록체인 기술 적용 가능성을
                      탐색하는 협업 이니셔티브입니다
                    </div>
                  </div>

                  {/* Description */}
                  <div className="mt-[2rem] ">
                    <div className="text-xl font-bold">Our purpose</div>
                    <div className="text-lightGrayColor">
                      프로젝트 펄스는 신한투자증권, SK증권, 금융IT 전문 기업
                      블록체인글로벌의 3사가 협업하여 진행합니다 프로젝트 펄스는
                      규제 요건 변화에 부합하는 지속적인 대응력을 확보하고,
                      고객사의 초기 비용을 최소화하여 사업 본질에 집중할 수
                      있도록 하는 서비스를 제공하여 토큰증권 시장에 활력을
                      불어넣는 것을 목표로 하고 있습니다
                    </div>
                    <div className="text-xl font-bold mt-[0.5rem]">
                      What services do we provide?
                    </div>
                    <div className="text-lightGrayColor">
                      해당 프로젝트는 조각투자·혁신금융서비스 사업자를 대상으로
                      고객사의 사업가치와 목표 일정을 고려하여 신속한 사업화를
                      지원하고, 토큰증권의 손쉬운 발행과 유통을 위한 최적의
                      블록체인 기반 금융 분산원장 인프라와 솔루션을 제공합니다
                      블록체인글로벌은 토큰증권 발행과 유통을 위한 블록체인 기반
                      인프라를, 신한투자증권과 SK증권은 증권 발행 전 과정에 걸친
                      비즈니스 컨설팅을 제공합니다
                    </div>
                    <div className="text-lightGrayColor mt-[0.25rem]">
                      사용자의 효율적인 인프라 구축과 편리한 운영, 업계 권장
                      수준의 데이터 암호화와 트랜잭션 권한 설정을 지원합니다
                      또한, 레거시 금융, 이종 블록체인 기술, 다양한 클라우드와의
                      유연한 연계를 통해 확장성을 확보하고 지속적인 업데이트를
                      제공합니다
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Sandbox Services Description */}
            <div className="text-5xl font-bold mt-[10rem]">
              <div>초기 비용 및 인프라 고민을 최소화하여 </div>
              <div>
                협력기관이
                <span className="text-primaryColor"> 비즈니스 본질</span>에
                집중할 수 있도록 돕습니다
              </div>
            </div>
            <div className="mt-[1rem] text-2xl font-bold">
              <div>
                경쟁력 있고 검증된 STO 금융 분산원장 인프라를 초기 구축비용
                최소화하여 지원하고
              </div>
              <div>
                규제변화에 발맞춰 고객사가 지속적으로 사업을 영위할 수 있도록
                확장성 높은 인프라 서비스를 제공합니다
              </div>
            </div>
            {/* row 1 */}
            <div className="flex space-x-10 mt-[3rem]">
              <div className="w-[50%]">
                <div className="sandBoxCard rounded-xl py-[1.5rem] px-[1rem]">
                  <div className="text-3xl font-extrabold">
                    블록체인 기반 발행·유통 분산원장 시스템
                  </div>
                  <div className="">
                    <img
                      alt=""
                      src={Slide1}
                      className="rounded-xl w-[100%] h-[10rem] my-[0.5rem]"
                    ></img>
                  </div>
                  <div className="text-lightGrayColor">
                    <ul className="list-outside list-disc pl-[1rem]">
                      <li>
                        사용량에 비례한 월 구독료 부과로 초기 구축비용 최소화
                      </li>
                      <li>
                        금융기관이 밸리데이터 노드로 참여하여 분산원장 신뢰도
                        확보
                      </li>
                      <li>
                        혁신금융서비스 지정을 대비하여 빠른 사업진행을 위한
                        비즈니스 컨설팅 제공
                      </li>
                      <li>
                        당사 제공 인프라 사용시 고객사 초기 비용 및 인프라 고민
                        최소화로 비즈니스 본질에 집중 가능
                      </li>
                      <li>
                        자체 기술로 구축한 풀스텍 코어 엔진을 활용하는 블록체인
                        기술을 사용하여 높은 보안성과 광범위한 상호 운용성을
                        지원
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-[50%]">
                <div className="sandBoxCard rounded-xl py-[1.5rem] px-[1rem]">
                  <div className="text-3xl font-extrabold">
                    지속가능한 인프라 제공
                  </div>
                  <div className="">
                    <img
                      alt=""
                      src={Slide2}
                      className="rounded-xl w-[100%] h-[10rem] my-[0.5rem]"
                    ></img>
                  </div>
                  <div className="text-lightGrayColor">
                    <ul className="list-outside list-disc pl-[1rem]">
                      <li>
                        ‘금융위 분산원장 요건(안)’ 기준에 맞는 기술스택을
                        기반으로 설계된 인프라 서비스
                      </li>
                      <li>
                        자체 구축할 경우 제도 변경에 대응하여 인프라 재구축 비용
                        발생하나, 해당 인프라 모델에서는 지속가능한 사업을
                        영위할 수 있도록 제도 변경에 맞춰 지속적인 기술 업데이트
                        제공
                      </li>
                      <li>
                        제도 도입시 빠르게 수용할 수 있는 비즈니스 프로세스 확보
                        가능
                      </li>
                      <li>
                        비즈니스 유연성과 확장성을 위해 업그레이드가 용이한
                        구조로 블록체인 네트워크 설계
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact */}
            <ContactPc />

            <DisclaimerPc />
          </div>
        </div>
      </div>
    </>
  );
}

export default SandboxPc;
