// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// components
import { ReactComponent as STOServiceSVG } from '../../assets/images/svgImage/STO_service.svg';

// components
import DisclaimerMo from '../../components/mobile/DisclaimerMo';
import ContactMo from '../../components/mobile/ContactMo';

// css
import '../../assets/css/mainpage.css';

// image

function StoMo() {
  const googleQA =
    'https://docs.google.com/forms/d/1yWvQVOCJYYd_RwmtrEE5UmrUKchCwubn0t57Uvd3UYo/viewform?edit_requested=true#responses';

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://shinhandigitalasset.com/sto',
              name: 'STO(Security Token Offering, 토큰증권)',
              description:
                '분산원장 기술 기반으로 디지털화된 “자본시장법상 증권”발행 유통 서비스 제공',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '서울 영등포구 여의도동 의사당대로 96',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'shs.block@shinhan.com',
                  contactType: 'email',
                },
              ],
              keywords: [
                '토큰증권',
                '가상자산',
                '비트코인',
                '블록체인',
                '금융위원회',
                '얼라이언스',
                '신한투자증권',
                '유진투자증권',
                '미래에셋',
                'KB증권',
                'NH증권',
                '한국투자증권',
                '키움증권',
                '삼성증권',
                'SK증권',
                'STO얼라이언스',
                'NFI',
                '넥스트 파이낸스 이니셔티브',
                'Next Finance Initiative',
                'ST 오너스',
                'ST 프렌즈',
                'STO 비전그룹',
                'STO',
                'Security Token',
                'Crypto',
                'Bitcoin',
                'Blockchain',
                'STO Alliance',
                '투자계약증권',
                '비금전신탁수익증권',
                'Securities',
                'Shinhan',
                'Token',
                'Bitcoin etf',
                'Bitcoin spot etf',
                '블록체인 인프라',
                '분산원장',
                'Project Pulse',
                '프로젝트 펄스',
                'RWA',
                '조각투자',
                'Investment',
                'Investment Contract',
                'Non-monetary Trust Beneficiary Certificates',
                'Fragment Investment',
                'Digital Asset',
                'Digital Wallet',
                'Web3',
                'Smart Contract',
                'Blockchain Global',
                '신종증권',
                '전자증권',
                'Digital',
                'ETF',
                'NFT',
                '혁신금융서비스',
                '조각투자사업자',
                '증권신고서',
                '예치금자금관리',
                '투자자보호기금',
                '에이판다',
                '발행시장',
                '유통시장',
                'Secondary Market',
                '서울옥션블루',
                '갤럭시아머니트리',
                '열매컴퍼니',
                '미술품 조각투자',
                '금융 솔루션',
                'Financial Advisory Service',
                'Business Consulting',
                'STO Platform',
                'STO 플랫폼',
                '증권업계',
                '매출채권',
                '유동화',
                '투자자문',
                '사업구조 설계',
                '증권구조',
                '드웰파이',
                'DwellFi',
                'Franklin Templeton',
                '금융 컨설팅',
                '유가증권',
                'Financial Institution',
                'Korean Financial Institution',
                'Korea Digital Asset',
                'Ethereum',
              ],
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '08:00:00',
                },
              ],
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div className="w-screen mt-[8rem]">
          {/* STO Description*/}
          <div className="w-[90%] mx-auto text-center">
            <div className="text-3xl w-[100%] mb-[2rem] font-bold">
              <span className="text-primaryColor">S</span>ecurity{' '}
              <span className="text-primaryColor">T</span>oken{' '}
              <span className="text-primaryColor">O</span>ffering
            </div>
            <div>
              <div className="flex-col">
                <div className="mt-[2rem]">
                  <div className="">
                    <div className="text-2xl font-bold">
                      Security Token Offeing(STO) 란?
                    </div>
                    <div className="text-lightGrayColor">
                      <div>
                        분산원장 기술 기반으로 디지털화된 “자본시장법상 증권”
                      </div>
                      <div className="text-sm mt-[0.5rem]">
                        <div className="mt-[0.3rem]">
                          토큰 : 거래단위 분할, 이전이 용이하고 빠른 결제 처리가
                          가능
                        </div>
                        <div className="mt-[0.3rem]">
                          증권 : 투자자에 부여되는 권리 내용이 증권이며,
                          자본시장법상 공시/영업/시장 규제 적용
                        </div>
                      </div>
                    </div>
                  </div>

                  <STOServiceSVG className="w-[70%] mt-[1rem] mx-auto" />

                  {/* Table */}
                  <div className="overflow-x-auto mt-[5rem]">
                    <table className="table text-">
                      {/* head */}
                      <thead>
                        <tr className="bg-base-200 text-xs">
                          <th></th>
                          <th>IPO</th>
                          <th>STO</th>
                          <th>ICO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* row 1 */}
                        <tr>
                          <th>대상물</th>
                          <td>
                            <div>실제 비즈니스 모델</div>
                            <div>(회사의 실적, 자금 흐름 등)</div>
                          </td>
                          <td>실제 비즈니스 모델이나 실물 자산</td>
                          <td>
                            블록체인 기술 기반의 추상적인 프로젝트 아이디어
                          </td>
                        </tr>
                        {/* row 2 */}
                        <tr>
                          <th>투자자</th>
                          <td>증권법 준수 투자자</td>
                          <td>증권법 준수 투자자</td>
                          <td>불특정 다수</td>
                        </tr>
                        {/* row 3 */}
                        <tr>
                          <th>투자 자금 형태</th>
                          <td>법정통화</td>
                          <td>법정통화 및 가상자산</td>
                          <td>
                            <div>가상자산</div>
                            <div>(비트코인, 이더리움)</div>
                          </td>
                        </tr>
                        {/* row 4 */}
                        <tr>
                          <th>발행인</th>
                          <td>명확</td>
                          <td>명확</td>
                          <td>불분명</td>
                        </tr>
                        {/* row 5 */}
                        <tr>
                          <th>발행 비용</th>
                          <td>높음(공시, 감사)</td>
                          <td>중간(ICO 대비 높음)</td>
                          <td>매우 낮음</td>
                        </tr>
                        {/* row 6 */}
                        <tr>
                          <th>중개 기관</th>
                          <td>증권사</td>
                          <td>증권사, 발행인 계좌관리기관</td>
                          <td>
                            <div>
                              SNS, 자체 홈페이지를 통한 홍보 및 자금조달
                            </div>
                            <div>(대부분 중개기관 X)</div>
                          </td>
                        </tr>
                        {/* row 7 */}
                        <tr>
                          <th>법적 규제</th>
                          <td>적용(자본시장법)</td>
                          <td>적용(자본시장법)</td>
                          <td>미적용(한국 전면 금지)</td>
                        </tr>
                        {/* row 8 */}
                        <tr>
                          <th>관리</th>
                          <td>기업의 수익 지분 소유</td>
                          <td>연동 자산의 지분 및 수익 소유</td>
                          <td>발행되는 토큰의 구매 권한 소유</td>
                        </tr>
                        {/* row 9 */}
                        <tr>
                          <th>블록체인 기술</th>
                          <td>X</td>
                          <td>O</td>
                          <td>O</td>
                        </tr>
                        {/* row 10 */}
                        <tr>
                          <th>런칭 프로세스</th>
                          <td>
                            <div>· IPO전 많은 의무 이행 필요</div>
                            <div>· 증권 거래 규제 당국의 승인 필요</div>
                          </td>
                          <td>
                            · 관련 증권법 준수 국가 금융 감독기관의 승인 및 규제
                            필요
                          </td>
                          <td>
                            <div>· 금융 당국의 승인이나 규정 준수 부재</div>
                            <div>
                              <div>
                                · 블록체인에 스마트 컨트랙트를 설정하기 위한
                              </div>
                              <div>기술적 지식, 성공적인 마케팅 필요</div>
                            </div>
                          </td>
                        </tr>
                        {/* row 11 */}
                        <tr>
                          <th>리스크</th>
                          <td>산업 및 회사의 재무, 영업 리스크</td>
                          <td>기초자산의 자산가치 변동 리스크</td>
                          <td>스캠, 토큰 가격변동, 프로젝트 실패</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* Description */}
                  <div className="mt-[5rem] text-lightGrayColor">
                    <div>
                      <div className="text-2xl font-bold">
                        증권 거래(유통)의 역사 = 기술을 통한 유통의 혁신
                      </div>
                      <div className="text-sm text-left">
                        <ul className="list-outside list-disc">
                          <li>
                            실물증권 : 종이증서 소지 = 권리 확보 → 유통
                            제약(상대방 찾기, 가격 협상, 체결 증명 등)
                          </li>
                          <li>
                            전자증권 : 종이증서가 아닌 증권사 계좌 기록을 통한
                            양도, 대량 거래 처리에 용이
                          </li>
                          <li>
                            토큰증권 : 분산원장 기록을 통한 양도, 다양한
                            비정형적 권리를 거래하는데 적합 → 증권 거래(유통)의
                            혁신
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-[2rem]">
                      <div className="text-2xl font-bold">
                        STO : 비정형적 권리에 대한 자금조달
                      </div>
                      <div className="text-sm text-left">
                        <ul className="list-outside list-disc">
                          <li>금융 규제를 준수한 자금 모집 → 투자자 보호</li>
                          <li>
                            소규모, 저비용 발행 가능 → 다양한 비정형적 상품 투자
                            기회
                          </li>
                          <li>
                            유통시장 개설 → 만기 또는 투자 장기화 이전, 시장
                            매도 가능
                          </li>
                          <li>
                            블록체인 기술 장점 활용하여 결제 기간을 단축하고,
                            글로벌 자금 조달 가능
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact */}
            <ContactMo />

            <DisclaimerMo />
          </div>
        </div>
      </div>
    </>
  );
}

export default StoMo;
