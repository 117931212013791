// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// components
import DisclaimerPc from '../../components/pc/DisclaimerPc';
import SpeakCardPc from '../../components/pc/SpeakCardPc';

// URL
import config from '../../config/config';

// css
import '../../assets/css/mainpage.css';

function AboutUsPc() {
  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  const teamDnaUrl1 = `${config.apiURL}/image/profile/team_dna1.webp`; // Sam
  const teamDnaUrl2 = `${config.apiURL}/image/profile/team_dna2.webp`; // Tony
  const teamDnaUrl3 = `${config.apiURL}/image/profile/team_dna3.webp`; // Leo

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://shinhandigitalasset.com/aboutUs',
              name: '신한투자증권 블록체인부 History',
              description:
                '2022년 7월 블록체인부서 신설, 2022년 12월 금융위원회 혁신금융서비스 지정, 2024년 1월 토큰증권 발행시스템 구축, 2024년 3월 Project Pulse 출범',
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'shs.block@shinhan.com',
                  contactType: 'email',
                },
              ],
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        {/* Mission */}
        <div className="text-center mt-[7rem]">
          <div className="text-xl font-semibold">Mission</div>
          <div className="text-5xl w-[70%] mx-auto mt-[1rem] font-bold">
            Empower businesses to seamlessly integrate digital assets, thereby
            advancing the frontiers of finance through enhanced accessibility
            and security
          </div>
        </div>

        {/* History */}
        <div className="text-center mt-[7rem]">
          <div className="text-xl font-semibold">History</div>
        </div>
        <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical mt-[2rem] w-[70%] mx-auto">
          <li>
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-start md:text-end mb-10">
              <time className="font-mono italic">2022.03</time>
              <div className="text-lg font-black">
                사내 <span className="text-primaryColor">블록체인 TF</span> 구성
              </div>
              신한투자증권은 블록체인 관련 사업을 전담하는 조직인 블록체인 TF를
              출범함으로써 디지털 자산 관련 사업을 본격화
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end mb-10">
              <time className="font-mono italic">2022.04</time>
              <div className="text-lg font-black">
                사업 <span className="text-primaryColor">전략 컨설팅</span> 실시
              </div>
              블록체인 신사업 발굴 전략 수립
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-start md:text-end mb-10">
              <time className="font-mono italic">2022.07</time>
              <div className="text-lg font-black">
                신한투자증권
                <span className="text-primaryColor"> 블록체인 부서</span> 신설
              </div>
              신한투자증권의 블록체인부는 블록체인 기술 리더십의 핵심으로서,
              신한금융그룹 관점에서 그룹사 내 블록체인 사업을 후원하고 당사 CDO
              및 그룹사 실무자를 중심으로 실질적인 협업을 추진하여 후견인 역할을
              강화
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end mb-10">
              <time className="font-mono italic">2022.10</time>
              <div className="text-lg font-black">
                블록체인 <span className="text-primaryColor">기술 컨설팅</span>{' '}
                계약
              </div>
              STO, 디지털 지갑, 블록체인 아키텍처, 스마트 컨트렉트 등 디지털
              자산 사업에 필요한 블록체인 기술 내재화를 위해 블록체인 기술
              컨설팅 계약 체결
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-start md:text-end mb-10">
              <time className="font-mono italic">2022.12 ~ 2023.05</time>
              <div className="text-lg font-black">
                <span className="text-primaryColor">
                  Security Token Offering
                </span>{' '}
                PoC(Proof of Concept) 프로젝트 착수
              </div>
              STO 플랫폼 서비스에 대한 기능검증(PoC) 진행. 블록체인 인프라 구축,
              디지털 월렛(지갑) 설계, 토큰 발행·청약·유통 시스템 설계, 기존
              금융시스템과의 연계를 진행함에 따라 증권사 내 블록체인 분야에 있어
              선도적인 경쟁력 확보
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end mb-10">
              <time className="font-mono italic">2022.12</time>
              <div className="text-lg font-black">
                금융위원회{' '}
                <span className="text-primaryColor">혁신금융서비스</span> 지정
              </div>
              신한투자증권은 합자법인인 에이판다파트너스와 함께 금융위원회로부터
              금융규제 샌드박스를 통해 ‘혁신금융서비스’로 지정 에이판다는
              ‘블록체인 기반의 금전채권 수익증권 거래 플랫폼’으로서 국내 대형
              상업용 부동산 및 사회기반시설을 담보로 우량한 부동산 자산의 채권을
              토큰화하여 유동화하는 서비스를 제공
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-start md:text-end mb-10">
              <time className="font-mono italic">2023.02</time>
              <div className="text-lg font-black">
                금융위원회{' '}
                <span className="text-primaryColor">STO 가이드라인</span> 발표{' '}
              </div>
              금융위원회는 디지털 금융 혁신을 위한 국정과제로, 자본시장법 규율
              내에서 STO를 허용하기 위한 토큰 증권 발행 유통 규율체계 정비
              방안을 발표 토큰증권을 전자증권법 제도상 증권발행 형태로 수용하고
              자본시장 제도의 투자자 보호장치 내에서 토큰증권을 발행 유통할 수
              있도록 가이드라인 제시
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end mb-10">
              <time className="font-mono italic">2023.09</time>
              <div className="text-lg font-black">
                대형
                <span className="text-primaryColor"> 증권사 컨소시엄</span> 구성
              </div>
              신한투자증권, KB증권, NH투자증권이 토큰증권 시장 공동 진출을 위해
              ‘토큰증권 증권사 컨소시엄 구성’ 전반에 대한 업무협약을 체결
              토큰증권 제도 입법화 상황에 따라 발빠르게 공통 프로젝트 착수가
              가능하도록 컨소시엄 구성을 위해 분산원장, 발행시스템, 유통시스템,
              구축방식 등을 논의하며 STO 플랫폼 설계 컨센서스를 정립
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-start md:text-end mb-10">
              <time className="font-mono italic">2023.09 ~ 2023.11</time>
              <div className="text-lg font-black">
                <span className="text-primaryColor">에이판다 서비스</span>{' '}
                통합테스트 완료{' '}
              </div>{' '}
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-start md:text-end mb-10">
              <time className="font-mono italic">2024.01</time>
              <div className="text-lg font-black">
                <span className="text-primaryColor">에이판다 서비스</span> 구축
                완료
              </div>
              STO 플랫폼 보안성 검증을 위해 신한투자증권 자체 보안성 심의 완료
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end mb-10">
              <time className="font-mono italic">2024.01</time>
              <div className="text-lg font-black">
                {' '}
                미국
                <span className="text-primaryColor"> STO 플랫폼</span>과 MOU
                체결
              </div>
              STO상품 개발 및 STO제도화 시장 선점을 위해 미국 핀테크 기업
              드웰파이와 MOU체결
            </div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-start md:text-end mb-10">
              <time className="font-mono italic">2024.03</time>
              <div className="text-lg font-black">
                STO 협업 이니셔티브{' '}
                <span className="text-primaryColor">Project Pulse </span> 출범
              </div>
              조각투자 및 혁신금융서비스 사업자 대상 블록체인 금융 인프라
              서비스를 제공하기 위해 프로젝트 펄스를 출범 프로젝트 펄스를 통해
              손쉬운 토큰증권 발행과 유통을 위한 최적의 블록체인 인프라와 금융
              솔루션(상품구조 설계, 증권신고서 작성 등) 제공
            </div>
          </li>
        </ul>

        {/* Our Members */}
        <div className="w-[70%] mx-auto mt-[10rem]">
          <div className="text-5xl font-bold">
            PART OF OUR
            <span className="text-primaryColor"> DNA</span>
          </div>
          <div className="h-[3px] bg-white mt-[1rem]"></div>
          <div className="mt-[2rem]">
            {/* Member 1 */}
            <SpeakCardPc
              man={teamDnaUrl1}
              position={'General Manager'}
              name={'Sam Lee'}
              text={
                '신한투자증권 블록체인부는 업무 프레임워크를 활용한 효율적인 분업과 자기주도적인 업무 추진 문화를 지향하는 애자일 조직입니다. 우리는 속도감 있는 협업을 위해 수평적으로 소통하며, 개인의 역량을 최대한 발휘할 수 있는 환경을 조성합니다. 블록체인 기술을 활용해 금융의 범위를 확장하고 혁신을 이끌어내기 위해 전통적인 금융부터 웹3까지 다양한 분야에 전문성을 가진 팀원들이 협업하고 있습니다. 우리는 빠르게 변화하는 자본시장의 최전선이자 금융과 블록체인 기술의 중심부에서 최신 기술과 트렌드를 선도하고자 노력합니다.'
              }
            />
          </div>
          <div className="mt-[2rem]">
            {/* Member 2 */}
            <SpeakCardPc
              man={teamDnaUrl2}
              position={'Deputy Senior Manager'}
              name={'Tony Kim'}
              text={
                '우리는 시장 분석을 통해 트렌드를 파악하고 성장 가능성이 있는 영역을 발굴하는 것을 핵심 역할로 삼고 있습니다. 특히 최근에 등장한 비정형적 증권, 그리고 블록체인과 금융을 결합한 신사업 전략 기획에 주력하고 있습니다. 우리는 조각투자사업자, 혁신금융서비스 사업자 등 다양한 국내 파트너사와의 협력을 바탕으로 고객에게 최고의 가치를 제공하기 위한 서비스와 상품을 개발 중입니다. 해외 STO 플랫폼과도 전략적인 제휴를 추진하며 글로벌 시장 진출에도 힘쓰고 있습니다. 이를 통해 파트너사와의 긴밀한 관계를 구축하고, 서로의 강점을 결합하여 상호 혜택을 극대화하고 더 나은 서비스를 제공하고자 노력합니다.'
              }
            />
          </div>
          <div className="mt-[2rem]">
            {/* Member 3 */}
            <SpeakCardPc
              man={teamDnaUrl3}
              position={'Deputy Senior Manager'}
              name={'Leo Lee'}
              text={
                '우리는 블록체인과 금융 모두에 능숙한 전문가들이 모여 새로운 기술을 활용한 혁신적인 서비스 제공과 개선을 담당하고 있습니다. 주요 업무로 블록체인 인프라의 구축과 유지보수, 예치금 자금관리 시스템 개발 등을 수행하며, 이를 통해 향후 토큰증권 입법 시 시장을 선점하기 위한 기술적 기반을 마련 중입니다. 우리는 블록체인 기술의 특성을 최대한 활용하여 고객들에게 효율적이고 신뢰할 수 있는 서비스를 제공하기 위해 노력하고 있습니다. 또한, 더 많은 사람들에게 새로운 투자 기회를 제공하기 위한 지속적인 연구와 개발을 진행하고 있습니다.'
              }
            />
          </div>
        </div>

        <div className="w-[70%] mx-auto mt-[5rem]">
          <DisclaimerPc />
        </div>
      </div>
    </>
  );
}

export default AboutUsPc;
