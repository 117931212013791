// modules
import React from 'react';
import { Link } from 'react-router-dom';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// URL
import config from '../../config/config';

// svg image
import { ReactComponent as ShinhanSecuritiesSVG } from '../../assets/images/svgImage/ShinhanSecurities.svg';
import { ReactComponent as SanboxInfraIconSVG } from '../../assets/images/svgImage/SandboxInfra_Icon.svg';
import { ReactComponent as InvestmentContractIconSVG } from '../../assets/images/svgImage/InvestmentContract_Icon.svg';
import { ReactComponent as NonMonetaryIconSVG } from '../../assets/images/svgImage/NonMonetaryTrust_Icon.svg';
import { ReactComponent as STOIconSVG } from '../../assets/images/svgImage/STO_Icon.svg';

// components
import TeamCardMo from '../../components/mobile/TeamCardMo';
import DisclaimerMo from '../../components/mobile/DisclaimerMo';
import ContactMo from '../../components/mobile/ContactMo';

// css
import '../../assets/css/mainpage.css';

// profile Image URL Data 저장
const teamManUrl1 = `${config.apiURL}/image/profile/team_man1.webp`; // Sam
const teamManUrl2 = `${config.apiURL}/image/profile/team_man2.webp`; // Tony
const teamManUrl3 = `${config.apiURL}/image/profile/team_man3.webp`; // Leo
const teamManUrl4 = `${config.apiURL}/image/profile/team_man4.webp`; // Aaron
const teamManUrl5 = `${config.apiURL}/image/profile/team_man5.webp`; // Jaden
const teamManUrl6 = `${config.apiURL}/image/profile/team_man6.webp`; // Peter

function MainMo() {
  const googleQA =
    'https://docs.google.com/forms/d/1yWvQVOCJYYd_RwmtrEE5UmrUKchCwubn0t57Uvd3UYo/viewform?edit_requested=true#responses';

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://shinhandigitalasset.com/',
              name: '신한 디지털 자산 (신한투자증권 블록체인부)',
              description:
                '신한 디지털 자산(신한투자증권 블록체인부) - 규제를 준수하는 사업 영역에서 고객의 문제를 해결하기 위해 금융 서비스를 혁신합니다.',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '서울 영등포구 여의도동 의사당대로 96',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'shs.block@shinhan.com',
                  contactType: 'email',
                },
              ],
              keywords: [
                '토큰증권',
                '가상자산',
                '비트코인',
                '블록체인',
                '금융위원회',
                '얼라이언스',
                '신한투자증권',
                '유진투자증권',
                '미래에셋',
                'KB증권',
                'NH증권',
                '한국투자증권',
                '키움증권',
                '삼성증권',
                'SK증권',
                'STO얼라이언스',
                'NFI',
                '넥스트 파이낸스 이니셔티브',
                'Next Finance Initiative',
                'ST 오너스',
                'ST 프렌즈',
                'STO 비전그룹',
                'STO',
                'Security Token',
                'Crypto',
                'Bitcoin',
                'Blockchain',
                'STO Alliance',
                '투자계약증권',
                '비금전신탁수익증권',
                'Securities',
                'Shinhan',
                'Token',
                'Bitcoin etf',
                'Bitcoin spot etf',
                '블록체인 인프라',
                '분산원장',
                'Project Pulse',
                '프로젝트 펄스',
                'RWA',
                '조각투자',
                'Investment',
                'Investment Contract',
                'Non-monetary Trust Beneficiary Certificates',
                'Fragment Investment',
                'Digital Asset',
                'Digital Wallet',
                'Web3',
                'Smart Contract',
                'Blockchain Global',
                '신종증권',
                '전자증권',
                'Digital',
                'ETF',
                'NFT',
                '혁신금융서비스',
                '조각투자사업자',
                '증권신고서',
                '예치금자금관리',
                '투자자보호기금',
                '에이판다',
                '발행시장',
                '유통시장',
                'Secondary Market',
                '서울옥션블루',
                '갤럭시아머니트리',
                '열매컴퍼니',
                '미술품 조각투자',
                '금융 솔루션',
                'Financial Advisory Service',
                'Business Consulting',
                'STO Platform',
                'STO 플랫폼',
                '증권업계',
                '매출채권',
                '유동화',
                '투자자문',
                '사업구조 설계',
                '증권구조',
                '드웰파이',
                'DwellFi',
                'Franklin Templeton',
                '금융 컨설팅',
                '유가증권',
                'Financial Institution',
                'Korean Financial Institution',
                'Korea Digital Asset',
                'Ethereum',
              ],
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '08:00:00',
                },
              ],
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div className="">
          {/* Blockchain Dept */}
          <div className="mt-[5rem] w-[60%] mx-auto">
            <ShinhanSecuritiesSVG />
          </div>
          <div className="mt-[2.5rem]">
            <div className="flex text-base font-semibold">
              <div className="flex-col text-right ml-auto">
                <div>Blockchain Dept.</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>of</div>
              </div>
              <div className="flex-col ml-[1rem] w-[15rem]">
                <div>is the creative</div>
                <div>development</div>
                <div>business partner</div>
                <div>your future</div>
              </div>
            </div>
            <div className="flex text-xs font-medium mt-[2rem]">
              <div className="flex-col text-right ml-auto">
                <div>Contact</div>
                <div>Office Hour</div>
              </div>
              <div className="flex-col ml-[1rem] w-[15rem]">
                <div>shs.block@shinhan.com</div>
                <div>8:00 ~ 17:00 (KST)</div>
              </div>
            </div>
          </div>
        </div>

        {/* Services */}
        <div className="mt-[10rem] text-center">
          <div className="flex-col">
            <div className="text-2xl font-bold mb-[2rem]">
              THE <span className="text-primaryColor">SERVICES</span> WE OFFER
            </div>
            <div className="relative w-[6rem] h-[6rem] mx-auto transition delay-80">
              <SanboxInfraIconSVG />
            </div>
            <div className="text-base font-bold mb-[0.5rem]">
              <div>Blockchain Infra Service</div>
              <div>(Project Pulse)</div>
            </div>
            <div className="text-xs text-lightGrayColor">
              <div>Blockchain-based decentralized ledger system</div>
              <div>
                Ensuring reliability by involving financial institutions as node
              </div>
              <div>Blockchain-based decentralized ledger system</div>
              <div>
                Upgradeable based on business flexibility and scalability
              </div>
            </div>
            <div className="mt-[1rem]">
              <Link
                to="/sandbox"
                onClick={componentDidUpdate}
                className="contact font-semibold text-sm py-[0.5rem] px-[1.7rem] rounded-full hover:text-darkGrayColor transition delay-80"
              >
                Details
              </Link>
            </div>
          </div>

          <div className="flex-col mt-[4rem]">
            <div className="w-[6rem] h-[6rem] mx-auto transition delay-80">
              <InvestmentContractIconSVG />
            </div>
            <div className="text-xl font-bold mb-[0.5rem]">
              Investment Contract
            </div>
            <div className="text-xs text-lightGrayColor">
              <div>Selection of Underlying Assets Advisory</div>
              <div>Preparation of Securities Registration Statement</div>
              <div>Deposit Services</div>
              <div>Investor Protection Fund Trust</div>
            </div>
            <div className="mt-[1rem]">
              <Link
                to="/investmentContract"
                onClick={componentDidUpdate}
                className="contact font-semibold text-sm py-[0.5rem] px-[1.7rem] rounded-full hover:text-darkGrayColor transition delay-80"
              >
                Details
              </Link>
            </div>
          </div>

          <div className="flex-col mt-[4rem]">
            <div className="w-[6rem] h-[6rem] mx-auto transition delay-80">
              <NonMonetaryIconSVG />
            </div>
            <div className="text-xl font-bold mb-[0.5rem]">
              <div>Non-monetary Trust</div>
              <div> Beneficiary Certificates</div>
            </div>
            <div className="text-xs text-lightGrayColor">
              <div>Selection of Underlying Assets Advisory</div>
              <div>Preparation of Securities Registration Statement</div>
              <div>Sandbox application and strategy advisory service</div>
              <div>Deposit Services and Investor Protection Fund Trust</div>
            </div>
            <div className="mt-[1rem]">
              <Link
                to="/trust"
                onClick={componentDidUpdate}
                className="contact font-semibold text-sm py-[0.5rem] px-[1.7rem] rounded-full hover:text-darkGrayColor transition delay-80"
              >
                Details
              </Link>
            </div>
          </div>

          <div className="flex-col mt-[4rem]">
            <div className="w-[6rem] h-[6rem] mx-auto transition delay-80">
              <STOIconSVG />
            </div>
            <div className="text-xl font-bold mb-[0.5rem]">
              Security Token Offering
            </div>
            <div className="text-xs text-lightGrayColor">
              <div>Providing blockchain-based distributed ledger services</div>
              <div>STO platform issuance and distribution services</div>
              <div>Structuring and advisory services for underlying assets</div>
              <div>Regulatory consulting services</div>
            </div>
            <div className="mt-[1rem]">
              <Link
                to="/sto"
                onClick={componentDidUpdate}
                className="contact font-semibold text-sm py-[0.5rem] px-[1.7rem] rounded-full hover:text-darkGrayColor transition delay-80"
              >
                Details
              </Link>
            </div>
          </div>
        </div>

        {/* Team */}
        <div className="text-center mt-[10rem]">
          <div className="text-lg font-semibold">Team</div>
          <div className="text-xl w-[90%] mx-auto mt-[1rem] font-bold">
            We innovate financial services to solve customer problems in
            regulatory compliant business areas
          </div>
        </div>
        <div className="flex flex-col items-center justify-center space-y-5 mt-[3rem]">
          <TeamCardMo
            image={teamManUrl1}
            frontName={'Seil Lee'}
            backName={'Sam Lee'}
            position={'General Manager'}
            comment={
              'Sam joined Shinhan Securities in 2022, leading the overall digital asset adoption as the head of the Blockchain Dept. Prior to Shinhan, he worked at Daewoo Securities and Hyundai Marine & Fire Insurance. He has extensive experience in both traditional and digital assets, and holds a double major in Business Administration and Statistics.'
            }
            contact={'223112@shinhan.com'}
          />
          <TeamCardMo
            image={teamManUrl2}
            frontName={'Taejun Kim'}
            backName={'Tony Kim'}
            position={'Deputy Senior Manager'}
            comment={
              "Tony joined Shinhan Securities in 2007, focusing on financial investment products and later on integrating AI into finance. Currently, he explores blockchain applications in finance, especially STO. He holds a master’s degree in Digital Financial Engineering from Korea University and a bachelor's degree in mathematics from KAIST."
            }
            contact={'kaju18@shinhan.com'}
          />
          <TeamCardMo
            image={teamManUrl3}
            frontName={'Yongho Lee'}
            backName={'Leo Lee'}
            position={'Deputy Senior Manager'}
            comment={
              'Leo joined Shinhan Securities in 2022, focusing on the STO business. He worked as a developer at LG CNS for 12 years, participating in various projects involving CBDCs, DIDs, NFTs, and more. He is confident that our team can successfully expand our business by leveraging expertise and great communication skills.'
            }
            contact={'yh.lee@shinhan.com'}
          />
          <TeamCardMo
            image={teamManUrl4}
            frontName={'Seongmun Huh'}
            backName={'Aaron Huh'}
            position={'Assistant Manager'}
            comment={
              'Aaron joined Shinhan Securities in 2021. He previously worked as an advisory manager at KPMG, working on lots of digital transformation projects for financial institutions. He is responsible for business planning and expansion, focusing on Securities Token Offering and investment contract securities.'
            }
            contact={'sungmoonhu@shinhan.com'}
          />
          <TeamCardMo
            image={teamManUrl5}
            frontName={'Jeseok Hong'}
            backName={'Jaden Hong'}
            position={'Associate'}
            comment={
              "Jaden recently joined Shinhan Securities, bringing with him a wealth of experience and a deep understanding of finance and tech principles. Holding a bachelor's degree in Economics, he began his career at Taycor Financial in the Los Angeles area of California, USA. He serves as a driving force for the team, characterized by true integrity."
            }
            contact={'jadenKR@shinhan.com'}
          />
          <TeamCardMo
            image={teamManUrl6}
            frontName={'Suhyeon Jung'}
            backName={'Peter Jung'}
            position={'Associate'}
            comment={
              "Peter recently joined Shinhan Securities, leading new business planning in our team. Previously, at A41, he developed liquid staking blockchain and multi-chain staking apps for B2C customers. With a background in Consumer and Child Studies from Seoul National University, he's keen on merging traditional and digital assets."
            }
            contact={'jsh@shinhan.com'}
          />
        </div>

        {/* Contact */}
        <ContactMo />

        <div className="w-[90%] mx-auto mt-[5rem]">
          <DisclaimerMo />
        </div>
      </div>
    </>
  );
}

export default MainMo;
