// modules
import React from 'react';
import languageAtom from '../../utils/GlobalState';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// css
import '../../assets/css/membersMo.css';

// components
import DisclaimerMo from '../../components/mobile/DisclaimerMo';

function ServicesMo() {
  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://shinhandigitalasset.com/services',
              name: '신한 디지털 자산(신한투자증권 블록체인부) 제공 서비스',
              description:
                '블록체인 기술을 통해 금융산업의 패러다임을 전환하는 서비스를 제공',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '서울 영등포구 여의도동 의사당대로 96',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'shs.block@shinhan.com',
                  contactType: 'email',
                },
              ],
              keywords: [
                '토큰증권',
                '가상자산',
                '비트코인',
                '블록체인',
                '금융위원회',
                '얼라이언스',
                '신한투자증권',
                '유진투자증권',
                '미래에셋',
                'KB증권',
                'NH증권',
                '한국투자증권',
                '키움증권',
                '삼성증권',
                'SK증권',
                'STO얼라이언스',
                'NFI',
                '넥스트 파이낸스 이니셔티브',
                'Next Finance Initiative',
                'ST 오너스',
                'ST 프렌즈',
                'STO 비전그룹',
                'STO',
                'Security Token',
                'Crypto',
                'Bitcoin',
                'Blockchain',
                'STO Alliance',
                '투자계약증권',
                '비금전신탁수익증권',
                'Securities',
                'Shinhan',
                'Token',
                'Bitcoin etf',
                'Bitcoin spot etf',
                '블록체인 인프라',
                '분산원장',
                'Project Pulse',
                '프로젝트 펄스',
                'RWA',
                '조각투자',
                'Investment',
                'Investment Contract',
                'Non-monetary Trust Beneficiary Certificates',
                'Fragment Investment',
                'Digital Asset',
                'Digital Wallet',
                'Web3',
                'Smart Contract',
                'Blockchain Global',
                '신종증권',
                '전자증권',
                'Digital',
                'ETF',
                'NFT',
                '혁신금융서비스',
                '조각투자사업자',
                '증권신고서',
                '예치금자금관리',
                '투자자보호기금',
                '에이판다',
                '발행시장',
                '유통시장',
                'Secondary Market',
                '서울옥션블루',
                '갤럭시아머니트리',
                '열매컴퍼니',
                '미술품 조각투자',
                '금융 솔루션',
                'Financial Advisory Service',
                'Business Consulting',
                'STO Platform',
                'STO 플랫폼',
                '증권업계',
                '매출채권',
                '유동화',
                '투자자문',
                '사업구조 설계',
                '증권구조',
                '드웰파이',
                'DwellFi',
                'Franklin Templeton',
                '금융 컨설팅',
                '유가증권',
                'Financial Institution',
                'Korean Financial Institution',
                'Korea Digital Asset',
                'Ethereum',
              ],
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '08:00:00',
                },
              ],
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div className="w-screen font-workSans mt-[8rem]">
          {/* Services */}
          <div className="w-[90%] mx-auto">
            <div className="text-base font-semibold">Services</div>
            <div className="text-3xl font-bold">
              <div> 블록체인 기술을 통해 </div>
              <div>
                {' '}
                <span className="text-primaryColor">
                  금융산업의 패러다임을 전환
                </span>
                하는 서비스를 제공합니다
              </div>
            </div>
            <div className="text-lg mt-[0.5rem]">
              <div>
                고객의 금융투자영역 확대를 위해 우수한 수준의 금융서비스를
                제공하고
              </div>
              <div>
                디지털 자산 산업 생태계 발전을 위해 기술 및 규제의 측면에서
                업계를 리드합니다
              </div>
            </div>
          </div>
          <div className="w-[90%] mx-auto mt-[3rem]">
            <div className="flex-col">
              {/* Sandbox Infra Service */}
              <div className="w-[100%] px-[1rem] py-[1.5rem] mt-[1rem] rounded-lg bg-lightBlackColor">
                <div className="flex-col">
                  <div className="w-[100%] text-center">
                    <div className="text-2xl font-bold">
                      Blockchain Infra Service (Project Pulse)
                    </div>
                    <div className="text-sm text-lightGrayColor">
                      <div>
                        고객사가 초기 비용 및 인프라 고민을 최소화하고 비즈니스
                        본질에 집중할 수 있도록
                      </div>
                      <div>
                        조각투자 및 혁신금융서비스 사업자 대상 블록체인 기반
                        금융 인프라 서비스를 제공합니다
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-[1rem]">
                    <Link to="/sandbox" onClick={componentDidUpdate}>
                      <button className="contact text-base py-[0.5rem] px-[1rem] rounded-full hover:text-darkGrayColor transition delay-80 hover:-translate-y-1">
                        Go Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Go Investment Contract Service */}
              <div className="w-[100%] px-[1rem] py-[1.5rem] mt-[1rem] rounded-lg bg-lightBlackColor">
                <div className="flex-col">
                  <div className="w-[100%] text-center">
                    <div className="text-2xl font-bold">
                      Investment Contract
                    </div>
                    <div className="text-sm text-lightGrayColor">
                      <div>
                        금융투자업의 전문성을 바탕으로 기존에 없던 비정형적
                        권리를 다룹니다
                      </div>
                      <div>
                        투자계약증권의 높은 이해를 갖춘 인력으로 구성된 당
                        부서는 투자계약증권 상품 구조화 및 증권신고서 작성
                        서비스를 제공합니다
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-[1rem]">
                    <Link to="/investmentContract" onClick={componentDidUpdate}>
                      <button className="contact text-base py-[0.5rem] px-[1rem] rounded-full hover:text-darkGrayColor transition delay-80 hover:-translate-y-1">
                        Go Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Go Non-monetary Trust Beneficiary Certificates Service */}
              <div className="w-[100%] px-[1rem] py-[1.5rem] mt-[1rem] rounded-lg bg-lightBlackColor">
                <div className="flex-col">
                  <div className="w-[100%] text-center">
                    <div className="text-2xl font-bold">
                      Non-monetary Trust Beneficiary Certificates
                    </div>
                    <div className="text-sm text-lightGrayColor">
                      <div>
                        금융투자업의 전문성을 바탕으로 비금전 자산 유동화 및
                        신탁 상품에 대한 권리를 다룹니다
                      </div>
                      <div>
                        비금전 신탁수익증권에 대한 높은 이해를 갖춘 인력으로
                        구성된 당 부서는 비금전 신탁수익증권 상품 구조화 및
                        증권신고서 작성 서비스를 제공합니다
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-[1rem]">
                    <Link to="/trust" onClick={componentDidUpdate}>
                      <button className="contact text-base py-[0.5rem] px-[1rem] rounded-full hover:text-darkGrayColor transition delay-80 hover:-translate-y-1">
                        Go Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Go STO Service */}
              <div className="w-[100%] px-[1rem] py-[1.5rem] mt-[1rem] rounded-lg bg-lightBlackColor">
                <div className="flex-col">
                  <div className="w-[100%] text-center">
                    <div className="text-2xl font-bold">
                      Security Token Offering (STO)
                    </div>
                    <div className="text-sm text-lightGrayColor">
                      규제의 틀 안에서 분산원장 기술을 사용하여 '증권형 디지털
                      자산'을 발행·유통하는 서비스를 제공합니다
                    </div>
                  </div>
                  <div className="flex justify-center mt-[1rem]">
                    <Link to="/sto" onClick={componentDidUpdate}>
                      <button className="contact text-base py-[0.5rem] px-[1rem] rounded-full hover:text-darkGrayColor transition delay-80 hover:-translate-y-1">
                        Go Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[90%] mx-auto">
            <DisclaimerMo />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesMo;
