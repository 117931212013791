// modules
import React from 'react';
import { Link } from 'react-router-dom';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';

// components
import { ReactComponent as InvestmentContractServiceSVG } from '../../assets/images/svgImage/InvestmentContract_service.svg';
import { ReactComponent as InvestmentContractServiceSVG1 } from '../../assets/images/svgImage/InvestmentContract_service_1.svg';
import { ReactComponent as InvestmentContractServiceSVG2 } from '../../assets/images/svgImage/InvestmentContract_service_2.svg';
import { ReactComponent as InvestmentContractServiceSVG3 } from '../../assets/images/svgImage/InvestmentContract_service_3.svg';
import { ReactComponent as InvestmentContractServiceSVG4 } from '../../assets/images/svgImage/InvestmentContract_service_4.svg';

// components
import DisclaimerMo from '../../components/mobile/DisclaimerMo';
import ContactMo from '../../components/mobile/ContactMo';

// css
import '../../assets/css/mainpage.css';

// image

function InvestmentContractMo() {
  const googleQA =
    'https://docs.google.com/forms/d/1yWvQVOCJYYd_RwmtrEE5UmrUKchCwubn0t57Uvd3UYo/viewform?edit_requested=true#responses';

  // jotai를 통해 language의 전역 상태값을 저장합니다
  const [language, setLanguage] = useAtom(languageAtom);

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://shinhandigitalasset.com/investmentContract',
              name: '투자계약증권 서비스',
              description:
                '투자계약증권 컨설팅 서비스, 증권신고서 작성 자문 서비스, 예치금 자금관리 서비스, 투자자보호기금 관리 및 투자자 모집 전략 자문 서비스 등 금융투자업의 전문성을 바탕으로 최적의 서비스 제공',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '서울 영등포구 여의도동 의사당대로 96',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'shs.block@shinhan.com',
                  contactType: 'email',
                },
              ],
              keywords: [
                '토큰증권',
                '가상자산',
                '비트코인',
                '블록체인',
                '금융위원회',
                '얼라이언스',
                '신한투자증권',
                '유진투자증권',
                '미래에셋',
                'KB증권',
                'NH증권',
                '한국투자증권',
                '키움증권',
                '삼성증권',
                'SK증권',
                'STO얼라이언스',
                'NFI',
                '넥스트 파이낸스 이니셔티브',
                'Next Finance Initiative',
                'ST 오너스',
                'ST 프렌즈',
                'STO 비전그룹',
                'STO',
                'Security Token',
                'Crypto',
                'Bitcoin',
                'Blockchain',
                'STO Alliance',
                '투자계약증권',
                '비금전신탁수익증권',
                'Securities',
                'Shinhan',
                'Token',
                'Bitcoin etf',
                'Bitcoin spot etf',
                '블록체인 인프라',
                '분산원장',
                'Project Pulse',
                '프로젝트 펄스',
                'RWA',
                '조각투자',
                'Investment',
                'Investment Contract',
                'Non-monetary Trust Beneficiary Certificates',
                'Fragment Investment',
                'Digital Asset',
                'Digital Wallet',
                'Web3',
                'Smart Contract',
                'Blockchain Global',
                '신종증권',
                '전자증권',
                'Digital',
                'ETF',
                'NFT',
                '혁신금융서비스',
                '조각투자사업자',
                '증권신고서',
                '예치금자금관리',
                '투자자보호기금',
                '에이판다',
                '발행시장',
                '유통시장',
                'Secondary Market',
                '서울옥션블루',
                '갤럭시아머니트리',
                '열매컴퍼니',
                '미술품 조각투자',
                '금융 솔루션',
                'Financial Advisory Service',
                'Business Consulting',
                'STO Platform',
                'STO 플랫폼',
                '증권업계',
                '매출채권',
                '유동화',
                '투자자문',
                '사업구조 설계',
                '증권구조',
                '드웰파이',
                'DwellFi',
                'Franklin Templeton',
                '금융 컨설팅',
                '유가증권',
                'Financial Institution',
                'Korean Financial Institution',
                'Korea Digital Asset',
                'Ethereum',
              ],
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '08:00:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  closes: '17:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '08:00:00',
                },
              ],
            })}
          </script>
        </Helmet>
      </>
      <div style={{ backgroundColor: '#000000' }} className="font-workSans">
        <div style={{ backgroundColor: '#000000' }} className="font-workSans">
          <div className="w-screen mt-[8rem]">
            {/* STO Description*/}
            <div className="w-[90%] mx-auto text-center">
              <div className="text-3xl w-[100%] mb-[2rem] font-bold">
                <span className="text-primaryColor">I</span>nvestment{' '}
                <span className="text-primaryColor">C</span>ontract
              </div>
              <div>
                <div className="flex-col">
                  <div className="mt-[2rem]">
                    <div className="">
                      <div className="text-2xl font-bold">
                        투자계약증권이란?
                      </div>
                      <div>
                        <div className="text-xl font-bold mt-[0.5rem]">
                          투자계약증권의 자본시장법상 정의 (제4조 제6항)
                        </div>
                        <div className="text-lightGrayColor">
                          특정 투자자가 그 투자자와 타인 간의 공동사업에 금전
                          등을 투자하고 주로 타인이 수행한 공동사업의 결과에
                          따른 손익을 귀속받는 계약상의 권리가 표시된 것
                        </div>
                        <div className="text-sm mt-[0.5rem] text-lightGrayColor">
                          <ul>
                            <li>공동사업 : 기초자산의 취득, 관리, 처분</li>
                            <li>
                              주로 타인이 수행한 : 투자자는 공유 지분권 취득 및
                              의결권 행사 가능, 공동사업 운영자는 전문성을
                              바탕으로 기초자산과 잠재적 판매자 구매자 물색
                            </li>
                            <li>
                              공동사업의 결과에 따른 손익 귀속 : 기초자산
                              처분손익 귀속
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <InvestmentContractServiceSVG className="w-[70%] mt-[1rem] mx-auto" />

                    {/* Description */}
                    <div className="mt-[2rem] text-sm">
                      <div className="">
                        <div className="text-xl font-bold">Our purpose</div>
                        <div className="text-lightGrayColor">
                          당 부서는 금융투자업의 전문성과 투자계약증권의 높은
                          이해를 갖춘 인력을 바탕으로 고객에게 최적의 서비스를
                          제공하여 고객의 비즈니스 번영에 힘쓰는 것에 목표를
                          두고 있습니다
                        </div>
                        <div className="text-xl font-bold mt-[0.5rem]">
                          What services do we provide?
                        </div>
                        <div className="text-lightGrayColor">
                          투자계약증권 구조 설계 컨설팅 서비스, 증권신고서 작성
                          자문 서비스, 예치금 자금관리 서비스, 투자자보호기금
                          관리 및 투자자 모집 전략 수립 서비스 등 투자계약증권
                          관련된 전반의 서비스를 제공합니다
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* STO Services Description */}
              <div className="text-3xl font-bold mt-[10rem]">
                Quickly <span className="text-primaryColor">write up</span> your{' '}
                <span className="text-primaryColor">contracts</span> with our{' '}
                <span className="text-primaryColor">experts</span>
              </div>
              <div className="mt-[2rem] text-2xl font-bold">
                금융투자업의 전문성을 바탕으로 기존에 없던 비정형적 권리를
                다룹니다
              </div>

              {/* row 1 */}
              <div className="flex-col mt-[2rem]">
                <div className="">
                  <div>
                    <InvestmentContractServiceSVG1 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-base font-bold mt-[1rem]">
                      투자계약증권 구조 설계 컨설팅 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>조각투자 사업구조 자문 서비스 제공</li>
                        <li>
                          기초자산의 증권형태 결정 및 상품구조화(투자계약증권)
                        </li>
                        <li>
                          금융투자상품 구조화 자문 및 개발, 투자자보호 방안 수립
                        </li>
                        <li>
                          높은 블록체인 기술 이해도와 다양한 경험을 보유한
                          인력으로 구성된 전담 부서 보유
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <div>
                    <InvestmentContractServiceSVG2 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-xl font-bold mt-[1rem]">
                      증권신고서 작성 자문 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>
                          전문기관과의 협업을 통해 증권신고서 작성 및 자문
                          서비스 제공 (1부 작성, 2부 검토)
                        </li>
                        <li>
                          투자계약증권은 기존에 없던 비정형적 권리를 다루기에
                          증권신고서 작성이 무척 까다로우며, 전문 기관의 지원이
                          필요
                        </li>
                        <li>
                          증권신고서 작성 경험이 풍부한 IB 인력, 조각투자 및
                          투자계약증권에 높은 이해도를 갖춘 인력으로 구성된 부서
                          보유
                        </li>
                        <li>
                          법무법인, 회계법인 등 외부 네트워크 확보를 통해
                          추가적인 협업 가능
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* row 2 */}
              <div className="flex-col">
                <div className="mt-[1rem]">
                  <div>
                    <InvestmentContractServiceSVG3 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-xl font-bold mt-[1rem]">
                      예치금 자금관리 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>
                          조각투자사업자 관련 투자자보호 요건: 투자자 예치금을
                          외부 금융기관 투자자 명의 계좌(가상계좌 포함)에
                          별도예치 필요
                        </li>
                        <li>
                          VPN 및 전용선 기반 API 연동 개발 및 통합테스트 진행
                        </li>
                        <li>
                          표준화된 API 명세서 제공 및 업체별 니즈에 맞는
                          개발요건 정의
                        </li>
                        <li>
                          조각투자사업자가 투자계약증권 등 발행시, 투자자의 청약
                          프로세스에 따라 원화 입출금 지원
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <div>
                    <InvestmentContractServiceSVG4 className="rounded-xl" />
                  </div>
                  <div>
                    <div className="text-xl font-bold mt-[1rem]">
                      투자자보호기금 관리 및 투자자 모집 전략 자문 서비스
                    </div>
                    <div className="text-sm text-lightGrayColor text-left">
                      <ul className="list-outside list-disc pl-[1rem]">
                        <li>
                          조각투자사업자의 투자자 피해 보상 체계 마련 지원 위해
                          제공 투자자보호기금 금전신탁 서비스 제공
                        </li>
                        <li>
                          조각투자상품 신규 발행시 5% 적립하여 해당 자금 신탁 후
                          만기까지 운용(조각투자사업자 또는 사후관리자가
                          투자자보호 목적으로 사용)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact */}
              <ContactMo />

              <DisclaimerMo />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestmentContractMo;
