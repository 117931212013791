// modules
import { useNavigate } from 'react-router-dom';
import languageAtom from '../../utils/GlobalState';
import { useAtom } from 'jotai';

// css
import '../../assets/css/noticeCardMo.css';
import React from 'react';

function NoticeCardMo(props) {
  // 공지상세 페이지로 보낼 데이터를 전송합니다
  const navigate = useNavigate();

  const noticeClick = (
    title,
    created_date,
    created_by,
    content,
    object_key,
  ) => {
    navigate('/noticeDetails', {
      state: { title, created_date, created_by, content, object_key },
    });
  };

  // jotai를 통해 language의 전역 상태값을 저장하고 관리합니다
  const [language, setLanguage] = useAtom(languageAtom);

  return (
    <>
      <div className="noticeCardMo rounded-xl mt-[1.5rem]">
        <div className="w-[85%] mx-auto my-[8%]">
          <div className="w-[100%] text-lg font-black truncate">
            {props.title}
          </div>
          <div className="flex mt-[1rem] mb-[0.5rem]">
            <span className="w-[50%]">
              <div className="text-xs font-black">
                {language === false ? '작성일' : 'Date'}
              </div>
              <div className="text-[10px] text-darkGrayColor mt-[0.1rem]">
                {props.created_date}
              </div>
            </span>
            <span className="w-[50%">
              <div className="text-xs font-black">
                {language === false ? '작성자' : 'Created by'}
              </div>
              <div className="text-[10px] text-darkGrayColor mt-[0.1rem]">
                {props.created_by}
              </div>
            </span>
          </div>
          <div className="font-black text-sm mt-[1rem]">
            {language === false ? '내용' : 'Content'}
          </div>
          <div className="w-[100%] h-[11rem] text-xs text-lightGrayColor overflow-hidden">
            {props.content}
          </div>
          <button
            className="w-[100%] h-[1.5rem] bg-primaryColor mt-[1rem] rounded-full hover:bg-primaryColorDark transition delay-100 drop-shadow-md bg-primaryColor mt-[1.5rem] rounded-2xl font-bold hover:-translate-y-1"
            onClick={() => {
              noticeClick(
                props.title,
                props.created_date,
                props.created_by,
                props.content,
                props.object_key,
              );
            }}
          >
            <div className="py-[rem] text-xs">Details</div>
          </button>
        </div>
      </div>
    </>
  );
}

export default NoticeCardMo;
