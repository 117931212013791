// modules
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// APIs
import { postReportData } from '../../apis/post';

function MyDropzone(props) {
  // upload할 파일을 저장한다
  const [uploadFile, setUploadFile] = useState(null);

  // 업로드된 파일에 대한 정보는 acceptedFiles에 담긴다
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles[0].type === 'application/pdf') {
      setUploadFile(acceptedFiles[0]);
      props.setFileName(acceptedFiles[0].name);
    } else {
      props.notPDFError();
    }
  }, []);

  const handleUpload = () => {
    if (uploadFile) {
      // 폼데이터 형성
      const formData = new FormData();

      formData.append('file', uploadFile);

      // post API요청
      const postReport = postReportData(formData);
      const getAPIData = () => {
        postReport
          .then((response) => {
            console.log(response);

            return response;
          })
          .then((response) => {
            if (response.data.success === true) {
              props.success();
              props.setFileName('');
              setUploadFile(null);
            } else {
              props.error();
            }
          });
      };

      getAPIData();
    } else {
      props.nothingError();
    }
  };

  // getInputProps : input데이터에 주는 속성을 설정할 수 있다
  // getRootProps : dropzone의 클릭, 드래그 등 각종 이벤트에 대응하는 함수가 들어있다
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const InputProps = {
    ...getInputProps(),
    multiple: false,
    accept: 'application/pdf',
  };

  const RootProps = {
    ...getRootProps(),
  };

  return (
    <>
      <div>
        <div {...RootProps}>
          <input {...InputProps} />
          <button className="w-[100%] h-[2rem] mt-[1rem] bg-green rounded-full hover:bg-darkGreen transition delay-100 drop-shadow-md">
            <div className="">Select File</div>
          </button>
        </div>
      </div>
      <button
        className="w-[100%] h-[2rem] mt-[1rem] bg-green rounded-full hover:bg-darkGreen transition delay-100 drop-shadow-md"
        onClick={handleUpload}
      >
        <div className="">Upload</div>
      </button>
    </>
  );
}

export default MyDropzone;
