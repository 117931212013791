// modules
import React from 'react';

// image
const favicon = require('../../assets/images/favicon.png');

function FooterMo() {
  return (
    <footer className="footer footer-center p-7 w-[100%] bg-beigeColor mt-[10rem] text-black">
      <aside>
        <img alt="favicon" src={favicon} className="w-[3rem]"></img>

        <p className="font-bold text-xs">
          SHINHAN SECURITIES CO.LTD. <br />
          Leading brokerage and <br />
          investment banking houses.
        </p>
        <p className="text-xs">Copyright © 2024 - All right reserved</p>
      </aside>
    </footer>
  );
}

export default FooterMo;
