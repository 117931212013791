// modules
import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// css
import '../../assets/css/adminPostNotice.css';

// APIs
import { postNoticeData } from '../../apis/post';
import { getLoginState, getLogout } from '../../apis/request';

// components
import LoginState from '../../components/pc/LoginStatePc';
import LogoutButton from '../../components/pc/LogoutButton';
import Loading from '../../components/pc/LoadingPc';
import ToastMessage from '../../components/pc/ToastMessagePc';

function AdminPostNoticePc() {
  // User가 서버로 post요청을 보낼 데이터를 저장합니다
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  // title를 실시간으로 저장합니다
  const saveTitle = (event) => {
    setTitle(event.target.value);
  };

  // content를 실시간으로 저장합니다
  const saveContent = (event) => {
    setContent(event.target.value);
  };

  // login 상태관리
  const [loggedIn, setLoggedIn] = useState(false);

  // loading 상태관리
  const [loding, setLoding] = useState(true);

  // Toast Message 성공 알람, 실패 알람
  const successNotice = () =>
    toast.success(' 공지사항 업로드 완료 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorNotice = (message) =>
    toast.error(' 공지사항 업로드 실패 ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const successLogout = () =>
    toast.success(' 로그아웃 성공 !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorLogout = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  const errorNotLogin = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  const postClick = (event) => {
    const postData = () => {
      postNoticeData(title, content)
        .then((response) => {
          console.log(response.data.success);

          return response;
        })
        .then((response) => {
          if (response.data.success === true) {
            successNotice();
            setTitle('');
            setContent('');
          } else {
            errorNotice(event);
          }
        });
    };

    postData();
  };

  const logOutClick = (event) => {
    const getAPIData = () => {
      getLogout()
        .then((response) => {
          console.log(response);
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            if (loggedIn === false) {
              errorNotLogin('현재 로그인 상태가 아닙니다');
            } else {
              setLoggedIn(false);
              successLogout();
            }
          } else {
            errorLogout(data);
          }
        });
    };

    getAPIData();
  };

  useEffect(() => {
    const loginState = getLoginState();
    const getAPIData = () => {
      loginState
        .then((response) => {
          return response.success;
        })
        .then((data) => {
          if (data === true) {
            setLoggedIn(true);
          }
          setLoding(false);
        });
    };

    getAPIData();
  }, []);

  return (
    <>
      <ToastMessage />
      <div className="mt-[10rem]">
        {loding ? (
          <div className=" mx-auto flex justify-center">
            <Loading />
          </div>
        ) : (
          <LoginState state={loggedIn} />
        )}
        <div>
          <LogoutButton logOutClick={logOutClick} />
        </div>
      </div>
      <div className="w-screen h-screen font-workSans mt-[2rem]">
        <div className="postNoticeCard rounded-xl mt-[1.5rem] w-[90%] mx-auto">
          <div className="w-[90%] mx-auto my-[3%]">
            <form></form>
            <div className="text-xl font-black">Title</div>
            <input
              className="text-black w-[100%] rounded-xl mt-[0.5rem]"
              onChange={saveTitle}
            />
            <div className="mt-[2rem]">
              {' '}
              <div className="text-xl font-black">Content</div>
              <textarea
                className="textarea text-black w-[100%] h-[20rem] rounded-xl mt-[0.5rem]"
                onChange={saveContent}
              />
              <div className="">
                <button className="w-[100%] h-[2rem] rounded-full hover:bg-darkGreen transition delay-100 drop-shadow-md bg-green mt-[0.5rem]">
                  <div className="px-[1rem]" onClick={postClick}>
                    Post
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPostNoticePc;
